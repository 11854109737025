import { Component, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ContractPerfService } from 'src/services/contract-perf.service';
import { ToastrService } from 'ngx-toastr';
import { Contractobj } from '../../interfaces/contractobj';
import { Master } from 'src/app/interfaces/master';

import { ExportExcel } from 'src/services/export-excel.model';
import { WdNotifyEmail } from '../../interfaces/wdNotifyEmail';
import { CommentGridDocs } from 'src/app/interfaces/CommentGridDocs';
import { ContractCalculation } from 'src/services/contract-calculation';
import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'

@Component({
  selector: 'app-contract-detail',
  templateUrl: './contract-detail.component.html',
  styleUrls: ['./contract-detail.component.css']
})
export class ContractDetailComponent {
  contractNumber: string;
  contractObj: Contractobj;
  contractId: number = 0;
  masterData: any;
  customerSegment: Master[];
  agreementType: Master[];
  paintBrand: Master[];
  paintLines: Master[];
  WD: Master[];
  contractStatus: Master[];
  contractStatusReason: Master[];
  contractType: Master[];
  province: Master[];
  payment: Master[];
  isCreateContract: boolean = false;
  isContractEdit: boolean = true;
  mprToDataAmt: any;
  mprToDataMonth: any;
  mprToDataVal: any;
  todayDate: any;
  parsedFromDate: any;
  mprPercentval: any;
  contractCategory: Master[];

  paymentfileCount: number = 0;
  paymentFileName: string[];
  paymentFileData: File[];
  paymentfileBuffer: any;

  displayComment: boolean = false;
  commentfileCount: number = 0;
  commentFileName: string[];
  commentFileData: File[];
  fileComment: string = "";
  fileBuffer: any;
  commentGridCol: any[]
  commentGridData: CommentGridDocs[] = [
    {
      "comment": "",
      "date": "",
      "docgrpId": "",
      "fileDetails": [
        {
          "fileName": "",
          "fileId": 0
        }
      ]
    }
  ];
  disableSubmit: boolean = false;
  salesData: any;
  salesCols: any[];
  contractEmail: Master[];
  wdcontractEmail: [];
  wdHeader: any;
  selectedwdHeader: any;
  isNotifyWD: boolean = false;
  isEmailContract: boolean = false;
  enablePopUpEmail: boolean = this.isEmailContract || this.isNotifyWD
  wdNotifyEmail: WdNotifyEmail = { contractId: 0, emails: [], status: '' }
  contractFormData: FormData = new FormData();
  isAdmin: boolean = false;
  auditlogs: any;
  @ViewChild('formFile', { static: false }) formFile: ElementRef;
  disablePaymentPlusbt: boolean = false;
  disableTrueUpPlusbt: boolean = false;
  disablePVITrueUpPlusbt: boolean = false;

  constructor(private contractPerfService: ContractPerfService, private router: Router, private toastr: ToastrService, private spinner: NgxSpinnerService, private _exportExcel: ExportExcel, private contractCal: ContractCalculation) {

    if (this.router.getCurrentNavigation().extras.state != undefined) {
      console.log(this.router.getCurrentNavigation().extras.state.contractId);
      this.contractId = this.router.getCurrentNavigation().extras.state.contractId

    }
    if (this.contractId > 0) {
      this.isCreateContract = true;
    }
  }

  ngOnInit() {
    if (sessionStorage.getItem('roleName') !== 'undefined') {
      this.isAdmin = sessionStorage.getItem('roleName') == 'Admin' ? true : false
    }
    this.getMasterTableData();
    this.getContractDetailData();
    this.getTodaysDate();
    this.getAuditlog();
    this.GetWdEmails();
    this.commentGridCol = [
      { field: 'date', header: 'Date', is_show: true, isEditable: false, serialNo: 1 },
      { field: 'comment', header: 'Comment', is_show: true, isEditable: true, serialNo: 2 },
      { field: 'fileName', header: 'Document', is_show: true, isEditable: false, serialNo: 3 }
    ]
    this.salesCols = [
      { header: 'Record ID#' },
      { header: 'Year' },
      { header: 'Salesforce Contract ID' },
      { header: 'Contract Status' },
      { header: 'Contract Status Reason' },
      { header: 'External Reference' },
      //{ header: 'MSO' },
      //{ header: 'MSO - External Reference' },
      { header: 'SF Acct. #' },
      { header: 'Company Name_Sales Record' },
      { header: 'Valid From' },
      { header: 'Valid To' },
      //{ header: 'Distributor Company Name' },
      { header: 'WD' },
      { header: 'Jan' },
      { header: 'Feb' },
      { header: 'Mar' },
      { header: 'Apr' },
      { header: 'May' },
      { header: 'Jun' },
      { header: 'Jul' },
      { header: 'Aug' },
      { header: 'Sep' },
      { header: 'Oct' },
      { header: 'Nov' },
      { header: 'Dec' },
      { header: 'Sales Total' },
      { header: 'Modified Date' },
      { header: 'Jan_AP' },
      { header: 'Feb_AP' },
      { header: 'Mar_AP' },
      { header: 'Apr_AP' },
      { header: 'May_AP' },
      { header: 'Jun_AP' },
      { header: 'Jul_AP' },
      { header: 'Aug_AP' },
      { header: 'Sep_AP' },
      { header: 'Oct_AP' },
      { header: 'Nov_AP' },
      { header: 'Dec_AP' },
      { header: 'AP Total' },
      { header: 'AP Modified Date' }
    ]

  }

  getAuditlog() {
    if (this.contractId > 0) {
      this.contractPerfService.getAuditlogs(this.contractId).subscribe(
        response => {

          this.auditlogs = response;
        },
        error => {
          this.toastr.error('Error Occurred');
        });
    }
  }

  getMasterTableData() {
    this.contractPerfService.getMasterData().subscribe(
      response => {

        this.masterData = response;
        this.customerSegment = this.masterData.filter(i => i.masterKey.includes("Customer Segment"))[0].masterkeyDataList;
        this.agreementType = this.masterData.filter(i => i.masterKey.includes("Agreement Type"))[0].masterkeyDataList;
        this.paintBrand = this.masterData.filter(i => i.masterKey.includes("Paint Brand"))[0].masterkeyDataList;
        this.paintLines = this.masterData.filter(i => i.masterKey.includes("Paint Lines"))[0].masterkeyDataList;
        this.WD = this.masterData.filter(i => i.masterKey.includes("WD"))[0].masterkeyDataList;
        this.contractStatus = this.masterData.filter(i => i.masterKey.includes("Contract Status"))[0].masterkeyDataList;
        this.contractStatusReason = this.masterData.filter(i => i.masterKey.includes("Contract Status Reason"))[0].masterkeyDataList;
        this.contractType = this.masterData.filter(i => i.masterKey.includes("Contract Type"))[0].masterkeyDataList;
        this.province = this.masterData.filter(i => i.masterKey.includes("Province"))[0].masterkeyDataList;
        this.contractEmail = this.masterData.filter(i => i.masterKey.includes("Email"))[0].masterkeyDataList;
        this.payment = this.masterData.filter(i => i.masterKey.includes("Payment Method"))[0].masterkeyDataList;
        this.contractCategory = this.masterData.filter(i => i.masterKey.includes("Contract Category"))[0].masterkeyDataList;

      },
      error => {
        this.toastr.error('Error Occurred');
      });
  }

  //Start Comment document download
  onSelectRow(rowObject) {

    let rowData = rowObject;
    this.downLoadDocument(rowData.rowData.fileId)

  }

  downLoadDocument(fileId: number) {
    this.contractPerfService.downloadCommentfile(fileId).subscribe(
      response => {
        const data = response;
        let fileExtension = "xlsx";
        if (response.fileName.includes(".docx")) {
          fileExtension = "docx";
        }
        else if (response.fileName.includes(".pdf")) {
          fileExtension = "pdf";
        }
        const arrayBuffer = this.base64ToArrayBuffer(data.fileData);
        this.createAndDownloadBlobFile(arrayBuffer, response.fileName, fileExtension);
      },
      error => {
        this.toastr.error('Error Occurred');
      });
  }

  ExportPDF() {
    let htmlContent = document.getElementById('exportdiv');

    html2canvas(htmlContent).then(canvas => {
      let imgData = canvas.toDataURL('image/png');
      let imgWidth = 210;
      let pageHeight = 295;
      let imgHeight = canvas.height * imgWidth / canvas.width;
      let heightLeft = imgHeight;
      let pdf = new jsPDF('p', 'mm');
      let position = 0;

      pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      let attachPDF: any;
      attachPDF = pdf.output('blob');
      let uploadedPDF = <File>attachPDF;
      const formData: FormData = new FormData();
      formData.append('file', uploadedPDF, "ContractPDF");
      this.contractFormData = formData;
      if (this.wdNotifyEmail.emails.length > 0) {
        this.spinner.show();
        let emails: string = '';
        for (let val of this.wdNotifyEmail.emails) {
          emails += val + ",";
        }
        this.contractPerfService.sendContractPDFEmail(formData, this.contractId, emails).subscribe(
          response => {

            this.spinner.hide();
            this.toastr.success('mail sent Successfully');
            this.isEmailContract = false;
            this.enablePopUpEmail = false;
          }, error => {

            this.spinner.hide();
            this.toastr.error('Error in sending email');
            this.isEmailContract = false;
            this.enablePopUpEmail = false;
          });
      }
    });

  }


  base64ToArrayBuffer(base64: string) {
    const binaryString = window.atob(base64); // Comment this if not using base64
    const bytes = new Uint8Array(binaryString.length);
    return bytes.map((byte, i) => binaryString.charCodeAt(i));
  }
  createAndDownloadBlobFile(body, filename, extension) {
    const blob = new Blob([body]);
    const fileName = `${filename}`;
    const link = document.createElement('a');
    // Browsers that support HTML5 download attribute
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', fileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

  }

  //End Comment document download

  bindMainShopPurchase() {
    this.contractObj.mainShopPurchase.forEach(res => {
      res.salesData.Jan = (res.salesData.Jan != '' ? res.salesData.Jan.replace(',', '.') : '');
      res.salesData.Feb = (res.salesData.Feb != '' ? res.salesData.Feb.replace(',', '.') : '');
      res.salesData.Mar = (res.salesData.Mar != '' ? res.salesData.Mar.replace(',', '.') : '');
      res.salesData.Apr = (res.salesData.Apr != '' ? res.salesData.Apr.replace(',', '.') : '');
      res.salesData.May = (res.salesData.May != '' ? res.salesData.May.replace(',', '.') : '');
      res.salesData.Jun = (res.salesData.Jun != '' ? res.salesData.Jun.replace(',', '.') : '');
      res.salesData.Jul = (res.salesData.Jul != '' ? res.salesData.Jul.replace(',', '.') : '');
      res.salesData.Aug = (res.salesData.Aug != '' ? res.salesData.Aug.replace(',', '.') : '');
      res.salesData.Sep = (res.salesData.Sep != '' ? res.salesData.Sep.replace(',', '.') : '');
      res.salesData.Oct = (res.salesData.Oct != '' ? res.salesData.Oct.replace(',', '.') : '');
      res.salesData.Nov = (res.salesData.Nov != '' ? res.salesData.Nov.replace(',', '.') : '');
      res.salesData.Dec = (res.salesData.Dec != '' ? res.salesData.Dec.replace(',', '.') : '');
      res.salesData.Total = (res.salesData.Total != '' ? res.salesData.Total.replace(',', '.') : '');
    });
  }

  bindMainShopPurchaseAP() {
    this.contractObj.mainShopPurchaseAP.forEach(res => {
      res.salesData.Jan = (res.salesData.Jan != '' ? res.salesData.Jan.replace(',', '.') : '');
      res.salesData.Feb = (res.salesData.Feb != '' ? res.salesData.Feb.replace(',', '.') : '');
      res.salesData.Mar = (res.salesData.Mar != '' ? res.salesData.Mar.replace(',', '.') : '');
      res.salesData.Apr = (res.salesData.Apr != '' ? res.salesData.Apr.replace(',', '.') : '');
      res.salesData.May = (res.salesData.May != '' ? res.salesData.May.replace(',', '.') : '');
      res.salesData.Jun = (res.salesData.Jun != '' ? res.salesData.Jun.replace(',', '.') : '');
      res.salesData.Jul = (res.salesData.Jul != '' ? res.salesData.Jul.replace(',', '.') : '');
      res.salesData.Aug = (res.salesData.Aug != '' ? res.salesData.Aug.replace(',', '.') : '');
      res.salesData.Sep = (res.salesData.Sep != '' ? res.salesData.Sep.replace(',', '.') : '');
      res.salesData.Oct = (res.salesData.Oct != '' ? res.salesData.Oct.replace(',', '.') : '');
      res.salesData.Nov = (res.salesData.Nov != '' ? res.salesData.Nov.replace(',', '.') : '');
      res.salesData.Dec = (res.salesData.Dec != '' ? res.salesData.Dec.replace(',', '.') : '');
      res.salesData.Total = (res.salesData.Total != '' ? res.salesData.Total.replace(',', '.') : '');
    });
  }

  getContractDetailData() {
    if (this.contractId > 0) {
      this.spinner.show();
      this.contractPerfService.getContractDetailData(this.contractId).subscribe(
        response => {

          this.contractObj = response;
          this.contractObj.comments = {
            comment: "",
            date: "",
            fileDetail: []
          }
          this.bindMainShopPurchase();
          this.bindMainShopPurchaseAP();
          this.bindCommentGridData(this.contractObj.commentGridData);
          this.mprToData();
          this.mprPerCal();
          this.contractMPRBalance();
          this.contractObj.Contract.FixedRebate = Number(this.contractObj.Contract.FixedRebate).toFixed(2);
          this.contractObj.Contract.AnnualFixedRebate = Number(this.contractObj.Contract.AnnualFixedRebate).toFixed(2);
          this.contractObj.Contract.VariableRebate = Number(this.contractObj.Contract.VariableRebate).toFixed(2);
          this.contractObj.Contract.MPR = Number(this.contractObj.Contract.MPR).toFixed(2);
          this.contractObj.Contract.MPR = this.contractCal.commafy(this.contractObj.Contract.MPR);
          this.contractObj.PaymentFiles = { fileDetail: [] }
          this.spinner.hide();
        },
        error => {
          this.toastr.error('Error Occurred');
        });
    }
    else {
      this.contractPerfService.getCreateContractDetailData().subscribe(
        response => {

          this.contractObj = response;
        },
        error => {
          this.toastr.error('Error Occurred');
        });
    }

  }

  bindCommentGridData(commentDocs) {

    for (let docs of commentDocs) {
      let obj = docs;
      let result = -1;
      let commentgridLength = 0;
      result = this.commentGridData.map(e => e.docgrpId).indexOf(obj["docgrpId"]);
      if (result >= 0) {
        this.commentGridData[result].fileDetails.push({
          "fileId": obj["fileId"],
          "fileName": obj["fileName"]
        })
      }
      else {
        commentgridLength = this.commentGridData[commentgridLength].docgrpId == "" ? 0 : this.commentGridData.length;
        if (commentgridLength == 0) {
          this.commentGridData[commentgridLength].docgrpId = obj["docgrpId"];
          this.commentGridData[commentgridLength].date = obj["date"];
          this.commentGridData[commentgridLength].comment = obj["comment"]
          this.commentGridData[commentgridLength].fileDetails[0].fileId = obj["fileId"]
          this.commentGridData[commentgridLength].fileDetails[0].fileName = obj["fileName"]
        }
        else {
          this.commentGridData.push(
            {
              "docgrpId": obj["docgrpId"],
              "date": obj["date"],
              "comment": obj["comment"],
              "fileDetails": [{
                "fileId": obj["fileId"],
                "fileName": obj["fileName"]
              }]
            })
        }

      }
    }

  }

  parseDate(dateString: string): Date {
    if (dateString) {
      return new Date(dateString);
    }
    return null;
  }

  resetMSO(e) {
    this.contractObj.MSO = [];
  }

  AddMSO(e) {

    if (this.contractObj.MSO.length <= 29) {
      this.contractObj.MSO.push({
        "RelatedMSOCustId": "",
        "RelatedMSOCustCRMId": "",
        "RelatedMSOCustomer": "",
        "RelatedMSOCustomerWDId": "",
        "CRM_Id_MSO": 0,
        "Customer_Id_MSO": 0
      })
    }
    else {
      console.log("disabled")
    }

  }

  AddtrueUp(e) {
    if (this.contractObj.TrueUp.length <= 9) {
      this.contractObj.TrueUp.push({
        "TrueUpDate": "",
        "TrueUpAmt": "",
        "TrueUpCumalative": "",
        "APTrueUpAmt": "",
        "APTrueUpDate": "",
        "TrueUpId": 0
      })
    }
    else {
      this.disableTrueUpPlusbt = true;
      console.log("disabled")
    }
  }

  AddPVItrueUp(e) {
    if (this.contractObj.PviTrueUp.length <= 9) {
      this.contractObj.PviTrueUp.push({
        "PviTrueUpDate": "",
        "PviTrueUpAmt": "",
        "PviTrueUpCumalative": "",
        "PVI_TrueUpAmt": "",
        "PVI_TrueUpDate": "",
        "PviTrueUpId": 0
      })
    }
    else {
      this.disablePVITrueUpPlusbt = true;
      console.log("disabled")
    }
  }

  AddPayment(e) {
    if (this.contractObj.Payment.length <= 9) {
      this.contractObj.Payment.push({
        "paymentDate": "",
        "reimbursementAmount": "",
        "paymentMethodID": "",
        "debitNoteDate": "",
        "paymentId": 0
      })
    }
    else {
      this.disablePaymentPlusbt = true;
      console.log("disabled")
    }
  }

  editContract() {
    this.isContractEdit = false;
  }

  notifyWD() {
    this.wdHeader = this.selectedwdHeader;
    this.isNotifyWD = true;
    this.isEmailContract = false;
    this.enablePopUpEmail = this.isEmailContract || this.isNotifyWD
    this.wdNotifyEmail.emails = []
  }

  emailContract() {
    this.wdHeader = "";
    this.isEmailContract = true;
    this.isNotifyWD = false;
    this.enablePopUpEmail = this.isEmailContract || this.isNotifyWD
    this.wdNotifyEmail.emails = []
  }

  displayCommentdialog() {
    this.displayComment = true;
  }

  //Start File Upload
  commentfileUpload(event) {


    this.commentfileCount = event.length;
    if (this.commentfileCount <= 3) {
      this.commentFileData = event;
      for (let k = 0; k < this.commentfileCount; k++) {
        this.contractObj.comments.fileDetail.push(
          {
            fileName: "",
            fileType: "",
            fileID: 0,
            fileData: ""
          }
        )
      }

      for (let i = 0; i < this.commentfileCount; i++) {
        this.getBase64_new(i, this.commentFileData[i], this.commentFileData[i].type, this.commentFileData[i].name);
      }
    }
    else {
      this.formFile.nativeElement.value = null;
      this.contractObj.comments.fileDetail = [];
      this.commentFileData = [];
      this.toastr.warning("Max of three files allowed, Please upload 3 files only at a time.")
    }

  }

  getBase64_new(index, file, fileType, name) {
    this.fileBuffer = "";
    let fileReader = new FileReader();
    fileReader.onload = (e) => {
      this.fileBuffer = fileReader.result;
      this.fileBuffer = this.fileBuffer.replace(`data:${fileType};base64,`, '');
      this.contractObj.comments.fileDetail[index].fileData = this.fileBuffer;
      this.contractObj.comments.fileDetail[index].fileName = name;
      this.contractObj.comments.fileDetail[index].fileType = fileType;
    }
    fileReader.readAsDataURL(file);
  }


  // End file Upload

  SubmitContractData() {
    this.spinner.show();
    this.contractObj.comments.comment = this.fileComment;
    this.disableSubmit = true;
    this.contractObj.Contract.MPR = this.contractObj.Contract.MPR ? this.contractObj.Contract.MPR.replace(/[,\s]+|[,\s]+/g, "") : '0';
    this.contractObj.Contract.MarketInvestment = this.contractObj.Contract.MarketInvestment ? this.contractObj.Contract.MarketInvestment.replace(/[,\s]+|[,\s]+/g, "") : '0';
    this.contractObj.Contract.AdditionalPayment = this.contractObj.Contract.AdditionalPayment ? this.contractObj.Contract.AdditionalPayment.replace(/[,\s]+|[,\s]+/g, "") : '0';
    this.contractObj.Contract.MPRToDate = this.contractObj.Contract.MPRToDate ? Number(this.contractObj.Contract.MPRToDate.toString().replace(/[,\s]+|[,\s]+/g, "")) : 0;

    this.contractPerfService.saveContractData(this.contractObj, this.contractId).subscribe(
      response => {

        this.spinner.hide();
        this.disableSubmit = false;
        this.toastr.success(response);
        this.router.navigate(['Contracts']);
      },
      error => {

        this.disableSubmit = false;
        this.spinner.hide();
        this.toastr.error('Error Occurred');
      });
  }

  SendEmail() {

    this.spinner.show();
    this.wdNotifyEmail.contractId = this.contractId;
    this.wdNotifyEmail.status = this.contractObj.Contract.ContractStatus;
    //this.wdcontractEmail.forEach((res:any) => {
    //  this.wdNotifyEmail.emails.push(res.email);
    //});
    this.wdNotifyEmail.emails = this.wdNotifyEmail.emails.filter((x: any) => x.email).map((e: any) => e.email);
    if (this.wdNotifyEmail.emails.length > 0) {
      this.contractPerfService.wdNotifyContract(this.wdNotifyEmail).subscribe(
        response => {
          this.spinner.hide();
          this.toastr.success(response);
          this.isNotifyWD = false;
          this.enablePopUpEmail = false;
        },
        error => {

          this.spinner.hide();
          this.isNotifyWD = false;
          this.toastr.error('Error Occurred when sending mail');
          this.enablePopUpEmail = false;
        }
      );
    } else {
      this.spinner.hide();
    }
  }



  calculateTrueUpCum(index) {

    let cumCal = 0;
    for (let j = 0; j < 10; j++) {
      cumCal += Number(this.contractObj.TrueUp[j].TrueUpAmt.replace(/[,\s]+|[,\s]+/g, ""));
      this.contractObj.TrueUp[j].TrueUpCumalative = String(cumCal);
    }

  }

  calculatePviTrueUpCum(index) {

    let cumCal = 0;
    for (let j = 0; j < 10; j++) {
      cumCal += Number(this.contractObj.PviTrueUp[j].PviTrueUpAmt.replace(/[,\s]+|[,\s]+/g, ""));
      this.contractObj.PviTrueUp[j].PviTrueUpCumalative = String(cumCal);
    }

  }

  extractSalesData() {

    if (this.contractId > 0) {
      this.spinner.show();
      this.contractPerfService.getSalesData(this.contractId, 0).subscribe(
        response => {
          this.salesData = response;
          this.spinner.hide();
          this._exportExcel.exportAsExcelFile(this.salesData, `SalesData_${new Date()}`, 'sheet1', this.salesCols)
          if (this.salesData.length <= 0) {
            this.toastr.error('No Sales Data found');
          }
        },
        error => {
          this.toastr.error('Error Occurred');
          this.spinner.hide();
        });
    }

  }

  getTodaysDate() {

    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();
    this.todayDate = mm + '/' + dd + '/' + yyyy;
  }



  mprToData() {
    if (Number(this.contractObj.Contract.MarketInvestment) >= 0 && Number(this.contractObj.Contract.ContractTerm) > 0 && this.contractObj.Contract.ValidFrom != "") {
      this.contractObj.Contract.MPRToDate = this.contractCal.calculateMPRtoDate(this.contractObj.Contract.MPR, this.contractObj.Contract.ContractTerm, this.contractObj.Contract.ValidFrom, this.todayDate, this.contractId, this.isContractEdit)
      this.mprPerCal();
    }
    this.contractObj.Contract.MarketInvestment = this.contractCal.commafy(Number(this.contractObj.Contract.MarketInvestment.replace(/[,\s]+|[,\s]+/g, "")).toFixed(2));
    this.contractObj.Contract.AdditionalPayment = this.contractCal.commafy(this.contractObj.Contract.AdditionalPayment);
  }

  mprPerCal() {
    if (Number(this.contractObj.Contract.ActualPaintPurchase) > 0) {
      this.contractObj.Contract.MPRPer = this.contractCal.calculateMPRPerCal(this.contractObj.Contract.ActualPaintPurchase, this.contractObj.Contract.MPRToDate)
      this.contractObj.Contract.MPRToDate = this.contractCal.commafy(this.contractObj.Contract.MPRToDate);

    }

  }

  contractMPRBalance() {
    this.contractObj.Contract.ContractMPRBalance = this.contractCal.calculatecontractMPRBalance(this.contractObj.Contract.MPR, this.contractObj.Contract.ActualPaintPurchase)

    this.contractObj.Contract.ContractMPRBalance = this.contractCal.commafy(this.contractObj.Contract.ContractMPRBalance);
    this.contractObj.Contract.ActualPaintPurchase = this.contractCal.commafy(this.contractObj.Contract.ActualPaintPurchase);
    this.contractObj.Contract.ActualAPPurchase = this.contractCal.commafy(this.contractObj.Contract.ActualAPPurchase);
  }

  bindContractDescription() {
    if (this.contractObj.Customer.CompanyName != "" && this.contractObj.Contract.ContractType != "0" && this.contractObj.Contract.ContractType != undefined) {
      const type = this.contractType.filter(i => i.keyId == parseInt(this.contractObj.Contract.ContractType))[0].keyValue;
      let shortname;
      if (type == 'Prepaid') {
        shortname = 'PP';
      } else if (type == 'Periodic - Monthly') {
        shortname = 'PM';
      } else if (type == 'Periodic - Quarterly') {
        shortname = 'PQ';
      } else if (type == 'Periodic - Semi Annually') {
        shortname = 'PSA';
      } else if (type == 'Periodic - Annually') {
        shortname = 'PA';
      } else if (type == 'Prepaid & Periodic') {
        shortname = 'PPP';
      }
      this.contractObj.Contract.ContractDescription = "Contract - " + shortname + " - " + this.contractObj.Customer.CompanyName;
    }
  }

  totalCalculation(amt: any, index: number) {
    let total = (parseFloat(this.contractObj.mainShopPurchase[index].salesData.Jan) || 0) +
      (parseFloat(this.contractObj.mainShopPurchase[index].salesData.Feb) || 0) +
      (parseFloat(this.contractObj.mainShopPurchase[index].salesData.Mar) || 0) +
      (parseFloat(this.contractObj.mainShopPurchase[index].salesData.May) || 0) +
      (parseFloat(this.contractObj.mainShopPurchase[index].salesData.Apr) || 0) +
      (parseFloat(this.contractObj.mainShopPurchase[index].salesData.Jun) || 0) +
      (parseFloat(this.contractObj.mainShopPurchase[index].salesData.Jul) || 0) +
      (parseFloat(this.contractObj.mainShopPurchase[index].salesData.Aug) || 0) +
      (parseFloat(this.contractObj.mainShopPurchase[index].salesData.Sep) || 0) +
      (parseFloat(this.contractObj.mainShopPurchase[index].salesData.Oct) || 0) +
      (parseFloat(this.contractObj.mainShopPurchase[index].salesData.Nov) || 0) +
      (parseFloat(this.contractObj.mainShopPurchase[index].salesData.Dec) || 0);
    this.contractObj.mainShopPurchase[index].salesData.Total = Math.round(total).toFixed(2).toString();
    console.log(amt + '-' + total);
  }

  bindCustomerDetails() {
    if (this.contractObj.Customer.SFAccountNumber != '' && this.contractObj.Customer.SFAccountNumber != undefined) {
      this.contractPerfService.getSFAccountDetails(this.contractObj.Customer.SFAccountNumber).subscribe((response) => {
        this.contractObj.Customer.CompanyName = response['CustomerName'];
        this.contractObj.Customer.DBAAKA = response['DBAAKA'];
        this.contractObj.Customer.City = response['BillingCity'];
        this.contractObj.Customer.Province = response['BillingState'];
        this.contractObj.Customer.PaintBrand = response['PaintBrand'];
        this.contractObj.Customer.PaintLine = response['PaintLine'];
        this.contractObj.Customer.Segment = response['Segment'];
        this.contractObj.Distribution.WDExternalRefID = response['ExternalReference'];
        this.contractObj.Distribution.WD = response['WD'];
        this.contractObj.Distribution.SFNumber = response['SalesForuceDistributerNumber'];
        this.contractObj.Distribution.DistributorName = response['DistributerName'];
        this.contractObj.Distribution.City = response['City'];
        this.contractObj.Distribution.Province = response['State'];
      })
    }
  }

  GoToContract() {
    this.wdHeader = "";
    this.selectedwdHeader = "";
    if (this.contractNumber != '' && this.contractNumber != undefined) {
      this.contractPerfService.getIdByContractNumber(parseInt(this.contractNumber)).subscribe((response) => {
        this.contractId = response['contractId'];
        this.getContractDetailData();
        this.getAuditlog();
        this.GetWdEmails();
      })
    }
  }

  GetWdEmails() {
    if (this.contractId > 0) {
      this.contractPerfService.getWdEmails(this.contractId).subscribe(
        response => {
          this.wdcontractEmail = response;
          if (response && response.length > 0) {
            this.wdHeader = response[0].keyvalue;
            this.selectedwdHeader = response[0].keyvalue;
          }
        },
        error => {
          this.toastr.error('Error Occurred');
        });
    }
  }

  compareAccounts = (item, selected) => {
    if (selected.country && item.country) {
      return item.country === selected.country;
    }
    if (item.name && selected.name) {
      return item.name === selected.name;
    }
    return false;
  };

  //Start File Upload
  paymentfileUpload(event) {


    this.paymentfileCount = event.length;
    if (this.paymentfileCount <= 3) {
      this.paymentFileData = event;
      for (let k = 0; k < this.paymentfileCount; k++) {
        this.contractObj.PaymentFiles.fileDetail.push(
          {
            fileName: "",
            fileType: "",
            fileID: 0,
            fileData: ""
          }
        )
      }

      for (let i = 0; i < this.paymentfileCount; i++) {
        this.getPaymentBase64_new(i, this.paymentFileData[i], this.paymentFileData[i].type, this.paymentFileData[i].name);
      }
    }
    else {
      this.formFile.nativeElement.value = null;
      this.contractObj.PaymentFiles.fileDetail = [];
      this.paymentFileData = [];
      this.toastr.warning("Max of three files allowed, Please upload 3 files only at a time.")
    }

  }

  getPaymentBase64_new(index, file, fileType, name) {
    this.paymentfileBuffer = "";
    let fileReader = new FileReader();
    fileReader.onload = (e) => {
      this.paymentfileBuffer = fileReader.result;
      this.paymentfileBuffer = this.paymentfileBuffer.replace(`data:${fileType};base64,`, '');
      this.contractObj.PaymentFiles.fileDetail[index].fileData = this.paymentfileBuffer;
      this.contractObj.PaymentFiles.fileDetail[index].fileName = name;
      this.contractObj.PaymentFiles.fileDetail[index].fileType = fileType;
    }
    fileReader.readAsDataURL(file);
  }


}
