import { Component, ViewEncapsulation,OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ContractPerfService } from 'src/services/contract-perf.service';
import {MessageService} from 'primeng/api';
import { ToastrService } from 'ngx-toastr';



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class HomeComponent implements OnInit {

  constructor(private service: ContractPerfService, private toastr: ToastrService, private router: Router, private spinner: NgxSpinnerService, private messageService: MessageService) { }
  ngOnInit(): void {
    this.service.setNavmenustatus(true);
  }
  
}
