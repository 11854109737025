import * as moment from 'moment'

export class ContractCalculation {

  mprToDataAmt: any;
  parsedFromDate: any;
  mprToDataMonth: any;
  mprToDataVal: any;
  mprPercentval: any;



  calculateMPRtoDate(mpr, contractTerm, validFrom, todayDate, contractId, isContractEdit) {

    this.mprToDataAmt = (Number(mpr.replace(/[,\s]+|[,\s]+/g, "")) / Number(contractTerm)).toFixed(2);
    this.parseValidFromDate(validFrom, contractId, isContractEdit);
    this.monthDiff(todayDate, this.parsedFromDate)
    this.mprToDataVal = ((this.mprToDataAmt) * (this.mprToDataMonth)).toFixed(2)
    return this.mprToDataVal
  }



  parseValidFromDate(validfrmDate: any, contractId, isContractEdit) {
    let parsedDate;
    if ((validfrmDate.indexOf('/') > -1)) {
      parsedDate = moment(validfrmDate, "MM/DD/YYYY");
    }
    else {
      parsedDate = moment(validfrmDate, "YYYY-MM-DD");
    }

    this.parsedFromDate = parsedDate.format("MM/DD/YYYY");
  }

  monthDiff(d1, d2) {
    const getDate = (date) => moment(date, 'MM/DD/YYYY').startOf('month')
    this.mprToDataMonth = moment(getDate([d1])).diff(moment(getDate([d2])), 'months', true)
  }

  calculateMPRPerCal(actualPaintPurchase, mprToDateVal) {
    mprToDateVal = mprToDateVal.replaceAll(',', '');
    actualPaintPurchase = actualPaintPurchase.replaceAll(',', '');
    if (mprToDateVal > 0) {
      this.mprPercentval = ((Number(actualPaintPurchase) / Number(mprToDateVal)) * 100).toFixed(2);
      return this.mprPercentval;
    }
    else {
      return 0;
    }

  }

  calculatecontractMPRBalance(mpr, actualPaintPurchase) {
    return String((Number(mpr) - Number(actualPaintPurchase)).toFixed(2));

  }

  commafy(num) {
    if (num) {
      num = num.toString().replaceAll(',', '');
      let str = num.toString().split('.');
      if (str[0].length >= 5) {
        str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
      }
      if (str[1] && str[1].length >= 5) {
        str[1] = str[1].replace(/(\d{3})/g, '$1 ');
      }
      return str.join('.');
    } else {
      return '0';
    }
  }

  convertStringWithCommatoNumber(res) {
    let checkString = new String(res);
    let onlyNmuber = /^[0-9,.]*$/.test(res);
    if (res != '' && res != null
      && res != undefined
      && checkString instanceof String
      && !Number.isNaN(res)
      && onlyNmuber) {
      res = String(res);
      let value = 0;
      value = parseFloat(res.replaceAll(',', ''));
      value = Math.round(value);
      return value;
    }
    return res;
  }
}
