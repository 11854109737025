
export class FiletrGridData {
    filteredData: any;
    filteredFields = [];
    month: any
    year: any = [{ value: 2010, id: 2010 }]
    selectedMonthYear: any = {
        selectedMonth: 1,
        selectedYear: 2010
    }
    todayDate: any;

    filterData(filterObj, gridData) {
        
        let { value, filter } = filterObj;
        if (value == '') {
            if (this.filteredFields.length) {
                this.filteredFields = this.filteredFields.filter(ff => ff.fieldName !== filter);
            } else {
                this.filteredFields = [];
            }
        } else {
            let foundField = this.filteredFields.filter(ff => ff.fieldName == filter);
            if (foundField.length) {
                this.filteredFields.forEach(ff => {
                    if (ff.fieldName == filter) {
                        ff.value = value;
                    }
                })
            } else {
                let fieldObj = {
                    fieldName: filter,
                    value: value
                };
                this.filteredFields.push(fieldObj);
            }
        }
        if (this.filteredFields.length) {
            let temp = gridData;
            this.filteredFields.forEach(ff => {
                temp = temp.filter(fd => fd[ff.fieldName].toUpperCase().includes(ff.value.toUpperCase()));
            })
            this.filteredData = temp;
        } else {
            this.filteredData = JSON.parse(JSON.stringify(gridData));
        }
    }
    getMonth() {
        this.month = [
            { value: "Jan", key: 1 },
            { value: "Feb", key: 2 },
            { value: "Mar", key: 3 },
            { value: "Apr", key: 4 },
            { value: "May", key: 5 },
            { value: "Jun", key: 6 },
            { value: "Jul", key: 7 },
            { value: "Aug", key: 8 },
            { value: "Sep", key: 9 },
            { value: "Oct", key: 10 },
            { value: "Nov", key: 11 },
            { value: "Dec", key: 12 },
        ]

        return this.month;
    }

    getYear() {
        for (let i = 2011; i <= 2040; i++) {
            this.year.push(
                { value: i, key: i }
            )
        }
        return this.year;
    }

    getCurrentMonthYear() {
        
        const d = new Date();
        this.selectedMonthYear.selectedMonth = d.getMonth() + 1;
        this.selectedMonthYear.selectedYear = d.getFullYear();
        return this.selectedMonthYear;

    }

    getTodaysDate() {
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0');
        let yyyy = today.getFullYear();
        this.todayDate = mm + '/' + dd + '/' + yyyy;
        return this.todayDate
    }

}
