import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ContractPerfService } from '../../../../services/contract-perf.service';
import { FiletrGridData } from '../../../../services/filterGridData';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-wd-email',
  templateUrl: './wd-email.component.html',
  styleUrls: ['./wd-email.component.css']
})
export class WdEmailComponent implements OnInit {
  masterKey: any;
  lookUpKeyddr: string = 'Agreement Type';
  masterValues: any;
  MasterCols: any;
  WDEmailForm: UntypedFormGroup;
  addNewFlag: boolean = false;
  addBtFlag: boolean = false;
  displayDeleteWDEmail = false;
  modelon = false;
  selectedWDEmailId: any;
  constructor(private contractPerfService: ContractPerfService, private spinner: NgxSpinnerService, private filetrGridData: FiletrGridData, private toastr: ToastrService, private fb: UntypedFormBuilder) {
    this.WDEmailForm = fb.group({
      Email: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.MasterCols = [
      { field: 'Id', header: 'ID', is_show: true, isEditable: false, serialNo: 1 },
      { field: 'Email', header: 'Email', is_show: true, isEditable: false, serialNo: 2 },
      { field: 'IsActive', header: 'IsActive', is_show: true, isEditable: false, serialNo: 3 },
    ]
    this.GetWdEmails();
  }

  GetWdEmails() {
    this.spinner.show();
    this.contractPerfService.getWdEmailList().subscribe(res => {
      if (res) {
        this.masterValues = res;
      }
      this.spinner.hide();
    });
  }

  deleteWDEmail() {
    this.spinner.show();
    this.contractPerfService.deleteWDEmail(this.selectedWDEmailId).subscribe(
      response => {
        this.spinner.hide();
        this.toastr.success("WDEmail deleted succesfully.")
        this.displayDeleteWDEmail = false;
        this.modelon = false;
        this.GetWdEmails();
      },
      error => {
        this.spinner.hide();
        this.toastr.error("Error Occurred")
        this.displayDeleteWDEmail = false;
        this.modelon = false;
      });
  }

  closeConfirmationpopUp() {
    this.displayDeleteWDEmail = false;
    this.modelon = false;
  }

  onSelectRow(rowObject) {
    let rowData = rowObject;
    if (rowData.type == 'delete') {
      this.displayDeleteWDEmail = true;
      this.modelon = true;
      this.selectedWDEmailId = rowData.rowData.Id;
    }
  }

  filterData(filterObj) {
    this.filetrGridData.filterData(filterObj, this.masterValues);
  }

  AddWDEmail() {
    if (this.WDEmailForm.invalid) {
      this.addBtFlag = true;
      return;
    }
    this.spinner.show();
    this.contractPerfService.addWDEmail(this.WDEmailForm.value).subscribe(res => {
      if (res) {
        this.WDEmailForm.get('Email').setValue('');
        this.toastr.success("Updated Successfully!");
        this.GetWdEmails();
        this.spinner.hide();
        this.addBtFlag = false;
      }
    });
  }

  Clear() {
    this.WDEmailForm.get('Email').setValue('');
  }

}
