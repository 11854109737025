import { Component} from '@angular/core';


@Component({
  selector: 'app-report',
  templateUrl: './report.component.html'
})
export class ReportComponent  {

}
