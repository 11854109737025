import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';


@Injectable()
export class AuthGuard implements CanActivate {
    isAuthSuccess: boolean
    constructor(private router: Router) {
    }
    async canActivate() {
        
      const token = sessionStorage.getItem('userId');
      //const token = "JilumuS";

        if (token) {
            console.log(token);
            this.isAuthSuccess = true;
        }
        else {
            console.log('invalid user')
            this.isAuthSuccess = false;
            this.router.navigate(["Login"]);
        }

        return this.isAuthSuccess;
    }

}
