import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ContractPerfService } from 'src/services/contract-perf.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  decryptedUser: any;
  encryptedUser: any;
  apiResp: any;
  userNotFound = false;
  userroleName:any;
  constructor(private _appServ: ContractPerfService,private spinner: NgxSpinnerService, private toast: ToastrService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    
    this.spinner.show();
    this.decryptedUser = sessionStorage.getItem('userId');
    this.route.queryParams.subscribe(params => {
      console.log(this.route,"route");
      console.log(params, "printing params");
      this.encryptedUser = params.loginUser;
      console.log(params['Username'],"encripteduser");
      if (this.decryptedUser == undefined || this.decryptedUser == null ){
        if ( this.encryptedUser == undefined || this.encryptedUser == null) {
          this.SAMLService();
        } else {
          
          this._appServ.getDecryptedUserDetails(this.encryptedUser).subscribe(response => {
            this.apiResp = response;
            console.log(response, "Decrypt method");
            sessionStorage.setItem('userId', this.apiResp);
            this.verifyUserAuthorization(this.apiResp);
            
          },      (error) => {
            this.toast.error(error, "Error!");
            this.spinner.hide();
          });
        }
      } else {
        this.verifyUserAuthorization(this.decryptedUser);
      }
     
    });
    this.spinner.hide();
  }

  


  SAMLService() {
    
    this.spinner.show();
    this._appServ.getSAMLService().subscribe((result) => {
      this.spinner.hide();
      window.location.href = result.toString();
    },
      (error) => {
        this.toast.error(error, "Error!");
        this.spinner.hide();
      });
  }

  verifyUserAuthorization(userID) {
    this._appServ.getUserInfo(userID).subscribe((res: any[]) => {
      this.userroleName = res;      
      if (res == null || res.length == 0) {
        this.userNotFound = true;
        this.router.navigate(['Login']);
      }
      else {
        sessionStorage.setItem('roleName', this.userroleName.roleName);
        this.router.navigate(['home']);
      }
    })
  }

}
