// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bg img { 
    width: 100%;
   height: 100%;
   
  }

  /* body  {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  } */`, "",{"version":3,"sources":["webpack://./src/app/components/home/home.component.css"],"names":[],"mappings":"AAAA;IACI,WAAW;GACZ,YAAY;;EAEb;;EAEA;;;;KAIG","sourcesContent":[".bg img { \r\n    width: 100%;\r\n   height: 100%;\r\n   \r\n  }\r\n\r\n  /* body  {\r\n    background-position: center;\r\n    background-repeat: no-repeat;\r\n    background-size: cover;\r\n  } */"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
