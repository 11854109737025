import { Component } from '@angular/core';
import { ContractPerfService } from 'src/services/contract-perf.service';
import { ExportExcel } from 'src/services/export-excel.model';
import { ContractCalculation } from 'src/services/contract-calculation';
import { FiletrGridData } from 'src/services/filterGridData';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-boardreport',
  templateUrl: './boardreport.component.html'
})


export class BoardreportComponent {

  boardReportGridCol: any[]
  boardReportData: any
  boardmonth:any
  boardyear:any = [{value:2010,id:2010}]
  filteredData: any;
  filteredFields = [];
  tempFilteredData: any;
  todayDate: any;
  mprToDataAmt: any;
  parsedFromDate: any;
  mprPercentval: any;
  mprToDataMonth: any;
  mprToDataVal: any;
  selectedMonth: number;
  selectedYear: number;
  selectedEndMonth: number;
  selectedEndYear: number;
  dynamicMonth:any[]
  dynamicMonth1:any[]
  dynamicMonth2:any[]
  constructor(private contractPerfService: ContractPerfService,private spinner: NgxSpinnerService,private toastr: ToastrService,private router: Router, private _exportExcel: ExportExcel, private contractCalculation:ContractCalculation, private filetrGridData: FiletrGridData) { }

  ngOnInit() {
    this.todayDate= this.filetrGridData.getTodaysDate();
    this.dynamicMonth = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
    this.boardReportGridCol = [
      { field: 'SFContractId', header: 'SF Contract ID', is_show: true, isEditable: false, serialNo: 0 },
      { field: 'status', header: 'Contract Status', is_show: true, isEditable: true, serialNo: 1 },
      { field: 'ExternalReference', header: 'WD Ext. Ref ID', is_show: true, isEditable: true, serialNo: 2 },
      { field: 'SFAccountNumber', header: 'SF Account Number', is_show: true, isEditable: false, serialNo: 3 },
      { field: 'CompanyName', header: 'Company Name', is_show: true, isEditable: false, serialNo: 4 },
      { field: 'City', header: 'Company City', is_show: true, isEditable: true, serialNo: 5 },
      { field: 'Province', header: 'Company Province', is_show: true, isEditable: false, serialNo: 6 },
      { field: 'mso', header: 'MSO', is_show: true, isEditable: false, serialNo: 7 },
      { field: 'ValidFrom', header: 'Valid From (mm/dd/yyyy)', is_show: true, isEditable: false, serialNo: 8 },
      { field: 'ValidTo', header: 'Valid To (mm/dd/yyyy)', is_show: true, isEditable: false, serialNo: 9 },
      { field: 'WDSFNumber', header: 'WD SF Number', is_show: true, isEditable: false, serialNo: 10 },
      { field: 'WarehouseDistributorCompName', header: 'WD Name', is_show: true, isEditable: false, serialNo: 11 },
      { field: 'WarehouseDistributor', header: 'WD', is_show: true, isEditable: true, serialNo: 12 },
      { field: 'NumberOfMonths', header: '# of Months (from the report selected Month & Year)', is_show: true, isEditable: true, serialNo: 13 },
      { field: 'Year', header: 'Year', is_show: true, isEditable: false, serialNo: 14 },
      { field: 'BRJan', header: 'Jan', is_show: true, isEditable: false, serialNo: 15 },
      { field: 'BRFeb', header: 'Feb', is_show: true, isEditable: false, serialNo: 16 },
      { field: 'BRMar', header: 'Mar', is_show: true, isEditable: true, serialNo: 17 },
      { field: 'BRApr', header: 'Apr', is_show: true, isEditable: false, serialNo: 18 },
      { field: 'BRMay', header: 'May', is_show: true, isEditable: false, serialNo: 19 },
      { field: 'BRJun', header: 'Jun', is_show: true, isEditable: true, serialNo: 20 },
      { field: 'BRJul', header: 'Jul', is_show: true, isEditable: false, serialNo: 21},
      { field: 'BRAug', header: 'Aug', is_show: true, isEditable: false, serialNo: 22 },
      { field: 'BRSep', header: 'Sep', is_show: true, isEditable: true, serialNo: 23 },
      { field: 'BROct', header: 'Oct', is_show: true, isEditable: false, serialNo: 24 },
      { field: 'BRNov', header: 'Nov', is_show: true, isEditable: false, serialNo: 25 },
      { field: 'BRDec', header: 'Dec', is_show: true, isEditable: true, serialNo: 26 },
      { field: 'TotalSales', header: 'Total Sales', is_show: true, isEditable: true, serialNo: 27 },
      { field: 'ContractDuration', header: 'Duration(Months)', is_show: true, isEditable: false, serialNo: 28 },
      { field: 'UpfrontPayment', header: 'UpFront Payment', is_show: true, isEditable: false, serialNo: 29 },
      { field: 'MPR', header: 'MPR', is_show: true, isEditable: true, serialNo: 30 },
      { field: 'mprReqToDate', header: 'MPR Req. (YTD)', is_show: true, isEditable: false, serialNo: 31 },
      { field: 'contractMPRBalance', header: 'Contract Bal. MPR', is_show: true, isEditable: false, serialNo: 32 },
      { field: 'VariableRebate', header: 'Variable Rebate', is_show: true, isEditable: true, serialNo: 33},
      { field: 'FixedRebate', header: 'Fixed Rebate', is_show: true, isEditable: false, serialNo: 34 },
      { field: 'PaintBrand', header: 'Paint Brand', is_show: true, isEditable: false, serialNo: 35 },
      { field: 'AgreementType', header: 'Agreement Type', is_show: true, isEditable: true, serialNo: 36 },
      { field: 'ContractStatusReason', header: 'Status Reason', is_show: true, isEditable: false, serialNo: 37 }
    ]

    this.boardmonth = this.filetrGridData.getMonth();
    this.boardyear = this.filetrGridData.getYear();
    this.selectedMonth = this.filetrGridData.getCurrentMonthYear().selectedMonth;
    this.selectedYear = this.filetrGridData.getCurrentMonthYear().selectedYear;
    this.selectedEndYear = this.filetrGridData.getCurrentMonthYear().selectedYear;
    this.selectedEndMonth = this.filetrGridData.getCurrentMonthYear().selectedMonth;
    this.bindReportGrid();
  }

  bindReportGrid() {
    this.spinner.show()
    this.contractPerfService.getBoardReport(this.selectedYear, this.selectedMonth, this.selectedEndYear, this.selectedEndMonth).subscribe(
      response => {
        this.boardReportData = response;
        this.boardReportData.forEach((element) => {
         element.TotalSales = (Number(element.BRJan) + Number(element.BRFeb)+ Number(element.BRMar)+ Number(element.BRApr)+ Number(element.BRMay)+ Number(element.BRJun)+ Number(element.BRJul)+ Number(element.BRAug)+ Number(element.BRSep)+ Number(element.BROct)+ Number(element.BRNov)+ Number(element.BRDec)).toFixed(2);
         element.mprReqToDate = this.contractCalculation.calculateMPRtoDate(element.UpfrontPayment, element.ContractDuration, element.ValidFrom,this.todayDate , 1, true);
         element.contractMPRBalance = this.contractCalculation.calculatecontractMPRBalance(element.MPR,element.mprActualToDate);
          element.UpfrontPayment = Math.trunc(element.UpfrontPayment);
          element.UpfrontPayment = this.contractCalculation.commafy(element.UpfrontPayment);
          element.mprReqToDate = this.contractCalculation.commafy(element.mprReqToDate);
          element.MPR = Math.trunc(element.MPR);
          element.MPR = this.contractCalculation.commafy(element.MPR);
          element.FixedRebate = Math.trunc(element.FixedRebate);
          element.VariableRebate = Math.trunc(element.VariableRebate);
        });
        this.spinner.hide();
      },
      error => {
        this.toastr.error('Error Occurred');
      });
  }

  filterData(filterObj) {
    this.filetrGridData.filterData(filterObj,this.boardReportData);
  }

  exportExcel() {
    let displayedContractJson: any[] = [];
    for (let originalValue of this.boardReportData) {
     let jsonObject = Object.assign({}, originalValue);
     jsonObject.Contract_id = undefined;
      jsonObject.mprActualToDate = undefined;
      jsonObject.BRJan = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.BRJan);
      jsonObject.BRFeb = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.BRFeb);
      jsonObject.BRMar = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.BRMar);
      jsonObject.BRApr = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.BRApr);
      jsonObject.BRMay = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.BRMay);
      jsonObject.BRJun = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.BRJun);
      jsonObject.BRJul = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.BRJul);
      jsonObject.BRAug = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.BRAug);
      jsonObject.BRSep = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.BRSep);
      jsonObject.BROct = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.BROct);
      jsonObject.BRNov = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.BRNov);
      jsonObject.BRDec = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.BRDec);
      jsonObject.MPR = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.MPR);
      jsonObject.NumberOfMonths = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.NumberOfMonths);
      jsonObject.ContractDuration = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.ContractDuration);
      jsonObject.TotalSales = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.TotalSales);
      jsonObject.UpfrontPayment = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.UpfrontPayment);
      jsonObject.contractMPRBalance = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.contractMPRBalance);
      jsonObject.mprReqToDate = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.mprReqToDate);
      jsonObject.VariableRebate = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.VariableRebate);
      jsonObject.FixedRebate = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.FixedRebate);
     let jsonString = JSON.stringify(jsonObject);
     displayedContractJson.push(JSON.parse(jsonString));
    }
    this._exportExcel.exportBoardReport(displayedContractJson, `BoardReport${new Date()}`, 'sheet1', this.boardReportGridCol, this.selectedMonth, this.selectedYear, this.selectedEndMonth, this.selectedEndYear);
  }

  onSelectRow(rowObject) {
    this.router.navigate(['Contracts/contracts-details'], { state: { contractId: rowObject.rowData.Contract_id } });
  }

}
