import { Component } from '@angular/core';
import { ContractPerfService } from 'src/services/contract-perf.service';
import { ExportExcel } from 'src/services/export-excel.model';
import { FiletrGridData } from 'src/services/filterGridData';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { ContractCalculation } from '../../../../services/contract-calculation';

@Component({
  selector: 'app-missing-purchase',
  templateUrl: './missing-purchase.component.html'
})
export class MissingPurchaseComponent  {

  missingPurchaseCol:any
  missingPurchaseReportData:any

  constructor(private contractPerfService: ContractPerfService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private router: Router,
    private _exportExcel: ExportExcel,
    private filetrGridData: FiletrGridData,
    private contractCalculation: ContractCalculation) { }
  
  ngOnInit() { 
    this.getMissingPurchaseGridHeader();
    this.bindMissingPurchaseGrid();
  }

  getMissingPurchaseGridHeader()
  {
    this.contractPerfService.getGridHeader('MissingPur').subscribe(
      response => {
        this.missingPurchaseCol = response; 
      },
      error => {
        this.toastr.error('Error Occurred');
      });
  }
  
  bindMissingPurchaseGrid() {
    this.spinner.show();
    this.contractPerfService.getMissingPurchaseReport().subscribe(
      response => {
        this.missingPurchaseReportData = response;
        this.spinner.hide();
      },
      error => {
        this.toastr.error('Error Occurred');
        this.spinner.hide()
      });
  }

  filterData(filterObj)
  {
       this.filetrGridData.filterData(filterObj,this.missingPurchaseReportData)
  }
 
  exportExcel() {
    let displayedContractJson: any[] = [];
    for (let originalValue of this.missingPurchaseReportData) {
     let jsonObject = Object.assign({}, originalValue);
      jsonObject.Contract_id = undefined;
      jsonObject.MPJan = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.MPJan);
      jsonObject.MPFeb = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.MPFeb);
      jsonObject.MPMar = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.MPMar);
      jsonObject.MPApr = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.MPApr);
      jsonObject.MPMay = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.MPMay);
      jsonObject.MPJun = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.MPJun);
      jsonObject.MPJul = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.MPJul);
      jsonObject.MPAug = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.MPAug);
      jsonObject.MPSep = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.MPSep);
      jsonObject.MPOct = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.MPOct);
      jsonObject.MPNov = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.MPNov);
      jsonObject.MPDec = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.MPDec);
      jsonObject.MPTotalSales = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.MPTotalSales);
     let jsonString = JSON.stringify(jsonObject);
     displayedContractJson.push(JSON.parse(jsonString));
    }
    this._exportExcel.exportAsExcelFile(displayedContractJson, `MissingPurchaseReport${new Date()}`, 'sheet1', this.missingPurchaseCol)
  }

  onSelectRow(rowObject) {
    this.router.navigate(['Contracts/contracts-details'], { state: { contractId: rowObject.rowData.Contract_id } });
  }

}
