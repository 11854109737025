import { Component, OnInit } from '@angular/core';
import { ContractPerfService } from 'src/services/contract-perf.service';
import { ExportExcel } from 'src/services/export-excel.model';
import { FiletrGridData } from 'src/services/filterGridData';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ContractCalculation } from '../../../../services/contract-calculation';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-master-list',
  templateUrl: './master-list.component.html'  
})
export class MasterListComponent implements OnInit {

  masterlistCol:any
  masterlistReportData:any

  constructor(private contractPerfService: ContractPerfService, private toastr: ToastrService, private router: Router, private _exportExcel: ExportExcel, private filetrGridData: FiletrGridData,
    private contractCalculation: ContractCalculation, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.getMissingPurchaseGridHeader();
    this.bindMissingPurchaseGrid();
  }

  getMissingPurchaseGridHeader()
  {
    this.contractPerfService.getGridHeader('Master').subscribe(
      response => {
        this.masterlistCol = response;      
      },
      error => {
        this.toastr.error('Error Occurred');
      });
  }

  bindMissingPurchaseGrid() {
    this.spinner.show();
    this.contractPerfService.getMasterReport().subscribe(
      response => {
        this.masterlistReportData = response;
        this.spinner.hide();
      },
      error => {
        this.toastr.error('Error Occurred');
      });
  }

  filterData(filterObj)
  {
       this.filetrGridData.filterData(filterObj,this.masterlistReportData)
  }

  exportExcel() {
    let displayedContractJson: any[] = [];
    for (let originalValue of this.masterlistReportData) {
     let jsonObject = Object.assign({}, originalValue);
      jsonObject.Contract_id = undefined;
      jsonObject.MasterJan = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.MasterJan);
      jsonObject.MasterFeb = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.MasterFeb);
      jsonObject.MasterMar = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.MasterMar);
      jsonObject.MasterApr = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.MasterApr);
      jsonObject.MasterMay = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.MasterMay);
      jsonObject.MasterJun = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.MasterJun);
      jsonObject.MasterJul = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.MasterJul);
      jsonObject.MasterAug = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.MasterAug);
      jsonObject.MasterSep = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.MasterSep);
      jsonObject.MasterOct = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.MasterOct);
      jsonObject.MasterNov = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.MasterNov);
      jsonObject.MasterDec = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.MasterDec);
      jsonObject.MasterActualAPPurchase = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.MasterActualAPPurchase);
      jsonObject.MasterActualPaintPurchase = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.MasterActualPaintPurchase);
      jsonObject.MasterAnnualFixedRebate = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.MasterAnnualFixedRebate);
      jsonObject.MasterContractBalanceMPR = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.MasterContractBalanceMPR);
      jsonObject.MasterContractDuration = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.MasterContractDuration);
      jsonObject.MasterFixedRebate = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.MasterFixedRebate);
      jsonObject.MasterMPR = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.MasterMPR);
      jsonObject.MasterMPRPercentage = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.MasterMPRPercentage);
      jsonObject.MasterMPRRequired = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.MasterMPRRequired);
      jsonObject.MasterMarketInvestment = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.MasterMarketInvestment);
      jsonObject.MasterTotalSales = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.MasterTotalSales);
      jsonObject.MasterTrueUp1 = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.MasterTrueUp1);
      jsonObject.MasterTrueUp2 = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.MasterTrueUp2);
      jsonObject.MasterTrueUp3 = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.MasterTrueUp3);
      jsonObject.MasterTrueUp4 = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.MasterTrueUp4);
      jsonObject.MasterTrueUp5 = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.MasterTrueUp5);
      jsonObject.MasterVariableRebate = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.MasterVariableRebate);
      jsonObject.MasterAPTrueUpAmt = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.MasterAPTrueUpAmt);
      let jsonString = JSON.stringify(jsonObject);
     displayedContractJson.push(JSON.parse(jsonString));
    }
    this._exportExcel.exportAsExcelFile(displayedContractJson, `MasterReport${new Date()}`, 'sheet1', this.masterlistCol)
  }

  onSelectRow(rowObject) {
    this.router.navigate(['Contracts/contracts-details'], { state: { contractId: rowObject.rowData.Contract_id } });
  }
}
