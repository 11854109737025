import { Component, ViewEncapsulation, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ContractPerfService } from 'src/services/contract-perf.service';
import { ToastrService } from 'ngx-toastr';
import { ImportFileComponent } from '../import-file/import-file.component';
import { ExportExcel } from 'src/services/export-excel.model';
import { ContractCalculation } from 'src/services/contract-calculation';
import { FiletrGridData } from 'src/services/filterGridData';


@Component({
  selector: 'app-contract',
  templateUrl: './contract.component.html',
  styleUrls: ['./contract.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ContractComponent {
  displayExportSales = false;
  displayDeleteContract = false;
  modelon = false;
  selectedContractId: any;
  defaultWidth: '100%';
  selectedCols: any[];
  contractData: any;
  filteredData: any;
  filteredFields = [];
  tempFilteredData: any;
  cols: any[];
  selectedRows = [];
  exportFileType: string;
  salesData: any;
  salesCols: any[];
  todayDate: any;
  isAdmin: boolean = false;
  yearArray = [];
  selectedYear = '';  
  @ViewChild(ImportFileComponent, { static: false }) private importFile: ImportFileComponent;

  constructor(private service: ContractPerfService, private toastr: ToastrService, private router: Router, private spinner: NgxSpinnerService, private _exportExcel: ExportExcel, private contractCalculation: ContractCalculation, private filetrGridData: FiletrGridData) { }

  ngOnInit() {
    if (sessionStorage.getItem('roleName') !== 'undefined') {
      this.isAdmin = sessionStorage.getItem('roleName') == 'Admin' ? true : false
    }
    this.getContractData();
    this.getTodaysDate();
    this.yearArray = this.filetrGridData.getYear();
  }

  getTodaysDate() {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();
    this.todayDate = mm + '/' + dd + '/' + yyyy;
  }


  getContractData() {

    this.selectedCols = [
      { field: 'contractNumber', header: 'Contract No', is_show: true, width: this.defaultWidth, isEditable: false, serialNo: 1 },
      { field: 'agreementType', header: 'Type', is_show: true, width: this.defaultWidth, isEditable: false, serialNo: 2 },
      { field: 'status', header: 'Status', is_show: true, width: this.defaultWidth, isEditable: false, serialNo: 3 },
      { field: 'accountNumber', header: 'Account #', is_show: true, width: this.defaultWidth, isEditable: false, serialNo: 4 },
      { field: 'accountName', header: 'Account Name', is_show: true, width: this.defaultWidth, isEditable: false, serialNo: 5 },
      { field: 'DBAAKA', header: 'DBA/AKA', is_show: true, width: this.defaultWidth, isEditable: false, serialNo: 5 },
      { field: 'WD', header: 'WD', is_show: true, width: this.defaultWidth, isEditable: false, serialNo: 6 },
      { field: 'marketInvestment', header: 'Market Investment($)', is_show: true, width: this.defaultWidth, isEditable: false, serialNo: 7 },
      { field: 'fixedRebate', header: 'Monthly Fixed Rebate', is_show: true, width: this.defaultWidth, isEditable: false, serialNo: 8 },
      { field: 'annualFixedRebate', header: 'Annual Fixed Rebate', is_show: true, width: this.defaultWidth, isEditable: false, serialNo: 9 },
      { field: 'variableRebate', header: 'Monthly Variable Rebate', is_show: true, width: this.defaultWidth, isEditable: false, serialNo: 10 },
      { field: 'MPR', header: 'MPR($)', is_show: true, width: this.defaultWidth, isEditable: false, serialNo: 11 },
      { field: 'MPRReqToDate', header: 'MPR ReqToDate($)', is_show: true, width: this.defaultWidth, isEditable: false, serialNo: 12 },
      { field: 'PerMPR', header: '% MPR', is_show: true, width: this.defaultWidth, isEditable: false, serialNo: 13 },
      { field: 'ActualPaintPurchase', header: 'Paint Purchase', is_show: true, width: this.defaultWidth, isEditable: false, serialNo: 14 },
      { field: 'contractStartDate', header: 'Start Date (mm/dd/yyyy)', is_show: true, width: this.defaultWidth, isEditable: false, serialNo: 15 },
      { field: 'contractEndDate', header: 'End Date (mm/dd/yyyy)', is_show: true, width: this.defaultWidth, isEditable: false, serialNo: 16 },
      
    ]

    this.salesCols = [
      { header: 'Record ID#' },
      { header: 'Year' },
      { header: 'Salesforce Contract ID' },
      { header: 'Contract Status' },
      { header: 'Contract Status Reason' },
      { header: 'External Reference' },
      //{ header: 'MSO' },
      //{ header: 'MSO - External Reference' },
      { header: 'SF Acct. #' },
      { header: 'Company Name_Sales Record' },
      { header: 'Valid From' },
      { header: 'Valid To' },
      //{ header: 'Distributor Company Name' },
      { header: 'WD' },
      { header: 'Jan' },
      { header: 'Feb' },
      { header: 'Mar' },
      { header: 'Apr' },
      { header: 'May' },
      { header: 'Jun' },
      { header: 'Jul' },
      { header: 'Aug' },
      { header: 'Sep' },
      { header: 'Oct' },
      { header: 'Nov' },
      { header: 'Dec' },
      { header: 'Jan_AP' },
      { header: 'Feb_AP' },
      { header: 'Mar_AP' },
      { header: 'Apr_AP' },
      { header: 'May_AP' },
      { header: 'Jun_AP' },
      { header: 'Jul_AP' },
      { header: 'Aug_AP' },
      { header: 'Sep_AP' },
      { header: 'Oct_AP' },
      { header: 'Nov_AP' },
      { header: 'Dec_AP' }
    ]

    this.cols = this.selectedCols
    this.spinner.show();
    this.service.getAllContractData().subscribe(
      response => {
        this.contractData = response;
        if (this.contractData.length <= 0) {
          this.toastr.error('No Contract Data found');
        }
        else {
          this.contractData.forEach((element) => {     
            element.MPRReqToDate = this.contractCalculation.calculateMPRtoDate(element.MPR, element.ContractDuration, element.ValidFrom, this.todayDate, 1, true);
            element.PerMPR = this.contractCalculation.calculateMPRPerCal(element.MPRActualTODate, element.MPRReqToDate);
            element.PerMPR = element.PerMPR + "%";
            element.marketInvestment = Math.trunc(element.marketInvestment);
            element.marketInvestment = this.contractCalculation.commafy(element.marketInvestment);
            element.MPRReqToDate = this.contractCalculation.commafy(element.MPRReqToDate);
            element.MPR = Math.trunc(element.MPR);
            element.MPR = this.contractCalculation.commafy(element.MPR);
            element.fixedRebate = Math.trunc(element.fixedRebate);
            element.annualFixedRebate = Math.trunc(element.annualFixedRebate);
            element.variableRebate = Math.trunc(element.variableRebate);
            
          });
        }


        this.filteredData = this.contractData;

        this.spinner.hide();

      },
      error => {
        this.toastr.error('Error Occurred');
        this.spinner.hide();
      });

  }

  openSalesPopup() {
    this.displayExportSales = true;
  }

  uploadContracts(event) {
    this.importFile.diaplayUpload(event);
  }

  receiveSelectedRows(event) {
    this.selectedRows = event;
  }

  onSelectRow(rowObject) {
    let rowData = rowObject;
    if (rowData.type == 'deleteContract') {
      this.displayDeleteContract = true;
      this.modelon = true;
      this.selectedContractId = rowObject.rowData.Contract_id;     
    }
    else {
      this.router.navigate(['Contracts/contracts-details'], { state: { contractId: rowObject.rowData.Contract_id } });
    }
  }

  createContract() {
    this.router.navigate(['Contracts/contracts-details'], { state: { contractId: 0 } });
  }

  filterData(filterObj) {
    this.filetrGridData.filterData(filterObj,this.contractData);
  }

  exportSalesRecords() {
    this.spinner.show();
    if (this.selectedYear == '') {
      this.toastr.warning('Please enter year to export sales');
      return;
    }
    this.service.getSalesData(0, this.selectedYear).subscribe(
      response => {
        this.salesData = response;
        this.spinner.hide();
        let displayedSalesJson: any[] = [];
        for (let originalValue of this.salesData) {
          let jsonObject = Object.assign({}, originalValue);
          jsonObject.Total = undefined;
          jsonObject.Modified = undefined;
          jsonObject.APTotal = undefined;
          jsonObject.APModified = undefined;
          let jsonString = JSON.stringify(jsonObject);
          displayedSalesJson.push(JSON.parse(jsonString));
        }
        this._exportExcel.exportAsExcelFile(displayedSalesJson, `SalesData_${new Date()}`, 'sheet1', this.salesCols)
        if (this.salesData.length <= 0) {
          this.toastr.error('No Sales Data found');
        } else {
          this.displayExportSales = false;
        }
      },
      error => {
        this.toastr.error('Error Occurred');
        this.spinner.hide();
      });
  }


  //Export Excel Logic
  exportExcel(event) {
    this.exportFileType = event;
    if (this.exportFileType == 'Sales') {
      this.exportSalesRecords();
    }
    else if (this.exportFileType == 'Contracts') {
      let displayedContractJson: any[] = [];
      for (let originalValue of this.contractData) {
       let jsonObject = Object.assign({}, originalValue);
       jsonObject.Contract_id = undefined;
       jsonObject.ContractDuration = undefined;
       jsonObject.ValidFrom = undefined;
        jsonObject.MPRActualTODate = undefined;
       let jsonString = JSON.stringify(jsonObject);
       displayedContractJson.push(JSON.parse(jsonString));
      }
      this._exportExcel.exportAsExcelFile(displayedContractJson, `Contract List_${new Date()}`, 'sheet1', this.cols)
    }

  }

  closepopUp() {
    this.displayExportSales = false;    
  }

  fileUploadStatus(FileUpload) {
    this.spinner.show();
    this.getContractData();
    this.spinner.hide();
  }

  closeConfirmationpopUp() {
    this.displayDeleteContract = false;
    this.modelon = false;
    
  }

  deleteContract() {
    this.spinner.show();
    this.service.deleteContact(this.selectedContractId).subscribe(
      response => {
        this.spinner.hide();
        this.toastr.success("Contract deleted succesfully.")
        this.displayDeleteContract = false;
        this.modelon = false;
        this.getContractData();        
      },
      error => {
        this.spinner.hide();
        this.toastr.error("Error Occurred")
        this.displayDeleteContract = false;
        this.modelon = false;
      });
    
  }

}
