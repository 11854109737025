import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ContractPerfService } from '../../../../services/contract-perf.service';
import { FiletrGridData } from '../../../../services/filterGridData';
import { ToastrService } from 'ngx-toastr';

interface AutoCompleteCompleteEvent {
  originalEvent: Event;
  query: string;
}

@Component({
  selector: 'app-wdemail-mapping',
  templateUrl: './wdemail-mapping.component.html',
  styleUrls: ['./wdemail-mapping.component.css']
})
export class WDEmailMappingComponent implements OnInit {
  masterKey: any;
  mappingValue: any;
  MasterCols: any;
  WDEmailMappingForm: UntypedFormGroup;
  addNewFlag: boolean = false;
  addBtFlag: boolean = false;

  invalidEmailFlag: boolean = false;

  displayDeleteWDEmailMapping = false;
  modelon = false;
  selectedWDEmailMappingId: any;
  WDOptions: any;
  WDEmails: any[] | undefined;
  filteredWDEmails: any[] | undefined;
  constructor(private contractPerfService: ContractPerfService, private spinner: NgxSpinnerService, private filetrGridData: FiletrGridData, private toastr: ToastrService, private fb: UntypedFormBuilder) {
    this.WDEmailMappingForm = fb.group({
      Id: [0],
      WdId: ['', Validators.required],
      EmailId: ['', Validators.required],
      IsActive: [true]
    });
  }

  ngOnInit(): void {
    this.MasterCols = [
      { field: 'Id', header: 'ID', is_show: true, isEditable: false, serialNo: 1 },
      { field: 'WD', header: 'WD', is_show: true, isEditable: false, serialNo: 2 },
      { field: 'Email', header: 'Email', is_show: true, isEditable: false, serialNo: 3 },
      { field: 'IsActive', header: 'IsActive', is_show: true, isEditable: false, serialNo: 4 },
    ];
    this.GetWDLookUp();
  }

  getWDEmails() {
    this.contractPerfService.getWdEmailList().subscribe(res => {
      this.WDEmails = res;
    });
  }

  getWDEmailMappingData() {
    this.GetWdEmailMapping();
  }

  GetWdEmailMapping() {
    this.spinner.show();
    let wdid = this.WDEmailMappingForm.get('WdId').value == '' ? 1 : this.WDEmailMappingForm.get('WdId').value;
    this.contractPerfService.getWdEmailMapping(wdid).subscribe(res => {
      if (res) {
        this.mappingValue = res;
      }
      this.spinner.hide();
    });
  }

  AddWDEmailMapping() {
    if (this.WDEmailMappingForm.invalid) {
      this.addBtFlag = true;
      return;
    }
    let email = this.WDEmailMappingForm.get('EmailId').value;
    let data = this.WDEmailMappingForm.value;
    data.EmailId = parseInt(email.Id);
    data.WdId = parseInt(data.WdId);

    if (isNaN(data.EmailId)) {
      this.addBtFlag = false;
      this.invalidEmailFlag = true;
      return;
    }

    this.spinner.show();
    this.contractPerfService.addWDEmailMapping(data).subscribe(res => {
      if (res) {
        if (res == "false") {
          this.toastr.success("EmailID already added with WD.");
          this.spinner.hide();
        } else {
          this.toastr.success("Added succesfully.");
          this.GetWdEmailMapping();
          this.spinner.hide();
          this.WDEmailMappingForm.get('EmailId').setValue('');
        }
        this.addBtFlag = false;

        this.invalidEmailFlag = false;

      } 
    });
  }

  Clear() {
    this.WDEmailMappingForm.get('EmailId').setValue('');
  }

  GetWDLookUp() {
    this.spinner.show();
    this.contractPerfService.getMasterValueByKey('WD').subscribe(res => {
      if (res) {
        this.WDOptions = res;
        this.WDEmailMappingForm.get('WdId').setValue(1);
        this.getWDEmails();
        this.GetWdEmailMapping();
      }
      this.spinner.hide();
    });
  }

  onSelectRow(rowObject) {
    let rowData = rowObject;
    if (rowData.type == 'delete') {
      this.displayDeleteWDEmailMapping = true;
      this.modelon = true;
      this.selectedWDEmailMappingId = rowData.rowData.Id;
    }
  }

  deleteWDEmailMapping() {
    this.spinner.show();
    this.contractPerfService.deleteWDEmailMapping(this.selectedWDEmailMappingId).subscribe(
      response => {
        this.spinner.hide();
        this.toastr.success("Deleted succesfully.")
        this.displayDeleteWDEmailMapping = false;
        this.modelon = false;
        this.GetWdEmailMapping();
      },
      error => {
        this.spinner.hide();
        this.toastr.error("Error Occurred")
        this.displayDeleteWDEmailMapping = false;
        this.modelon = false;
      });
  }

  closeConfirmationpopUp() {
    this.displayDeleteWDEmailMapping = false;
    this.modelon = false;
  }

  filterWDEmails(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.WDEmails as any[]).length; i++) {
      let WDEmail = (this.WDEmails as any[])[i];
      if (WDEmail.Email.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(WDEmail);
      }
    }

    this.filteredWDEmails = filtered;
  }

}
