import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ContractPerfService } from 'src/services/contract-perf.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {
  userRole: any
  isAdmin: boolean = false;
  clickEventsubscription: Subscription;
  changeText: boolean;

  constructor(private serv: ContractPerfService, private route: Router) {
    this.menuConfig();
    this.clickEventsubscription = this.serv.getNavmenustatus().subscribe(() => {
      this.menuConfig();
    });
    this.changeText = false;
  }


  menuConfig() {
    console.log("3" + sessionStorage.getItem('roleName'))
    if (sessionStorage.getItem('roleName') !== 'undefined') {
      this.userRole = sessionStorage.getItem('roleName');
      this.isAdmin = this.userRole == 'Admin' ? true : false
    }
    else {
      this.userRole = '';
    }
  }

  navigateDashboard() {
    let URL = `${environment.dashboardURL}`
    window.open(URL, '_blank');
  }

}
