import { Component } from '@angular/core';
import { ContractPerfService } from 'src/services/contract-perf.service';
import { ExportExcel } from 'src/services/export-excel.model';
import { ContractCalculation } from 'src/services/contract-calculation';
import { FiletrGridData } from 'src/services/filterGridData';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-true-up-report',
  templateUrl: './true-up-report.component.html'
})
export class TrueUpReportComponent {

  trueUpGridCol: any[]
  trueUpReportData: any
  filteredData: any;
  filteredFields = [];
  tempFilteredData: any;
  todayDate: any;
  mprToDataAmt: any;
  parsedFromDate: any;
  mprPercentval: any;
  mprToDataMonth: any;
  mprToDataVal: any;
  selectedMonth:number
  selectedYear:number
  trueupmonth:any
  trueupyear:any = [{value:2010,id:2010}]
  constructor(private contractPerfService: ContractPerfService,private spinner: NgxSpinnerService,private toastr: ToastrService,private router: Router, private _exportExcel: ExportExcel, private contractCalculation:ContractCalculation, private filetrGridData: FiletrGridData) { }

  ngOnInit() {
    this.todayDate= this.filetrGridData.getTodaysDate();

    this.getGridHeader();

    this.trueupmonth = this.filetrGridData.getMonth();
    this.trueupyear = this.filetrGridData.getYear();
    this.selectedMonth = this.filetrGridData.getCurrentMonthYear().selectedMonth;
    this.selectedYear = this.filetrGridData.getCurrentMonthYear().selectedYear;
    this.bindTrueUpGrid();
  }

 
  getGridHeader()
  {
    this.contractPerfService.getGridHeader('TrueUp').subscribe(
      response => {
        this.trueUpGridCol = response; 
      },
      error => {
        this.toastr.error('Error Occurred');
      });
  }
 
  
  bindTrueUpGrid() {
    this.spinner.show();
    this.contractPerfService.getTrueUpReport(this.selectedYear, this.selectedMonth).subscribe(
      response => {
        this.trueUpReportData = response;
        this.trueUpReportData.forEach((element) => {
          element.MPRReqToDate = this.contractCalculation.calculateMPRtoDate(element.UpfrontPayment, element.ContractDuration, element.ValidFrom,this.todayDate , 1, true);
          element.PerMPR = this.contractCalculation.calculateMPRPerCal(element.MPRActualTODate,element.MPRReqToDate);
          element.ContractBalanceMPR = this.contractCalculation.calculatecontractMPRBalance(element.MPR,element.MPRActualTODate);
          element.ContractBalanceMPR = '$ ' + this.contractCalculation.commafy(element.ContractBalanceMPR);
          element.PerMPR = element.PerMPR + "%";
          element.UpfrontPayment = Math.trunc(element.UpfrontPayment);
          element.UpfrontPayment = this.contractCalculation.commafy(element.UpfrontPayment);
          element.MPRReqToDate = this.contractCalculation.commafy(element.MPRReqToDate);
          element.MPR = Math.trunc(element.MPR);
          element.MPR = this.contractCalculation.commafy(element.MPR);
          element.FixedRebate = Math.trunc(element.FixedRebate);
          element.VariableRebate = Math.trunc(element.VariableRebate);
          element.trueup_amount = this.contractCalculation.commafy(element.trueup_amount);
          element.trueup_cumulative = this.contractCalculation.commafy(element.trueup_cumulative);
          element.MPRActualTODate = '$ ' + this.contractCalculation.commafy(element.MPRActualTODate);
          element.TrueUpPer = element.TrueUpPer ? element.TrueUpPer.replace(',', '.') : element.TrueUpPer;
          this.spinner.hide();
        });
      },
      error => {
        this.toastr.error('Error Occurred');
        this.spinner.hide();
      });
  }

 


  filterData(filterObj) {
    this.filetrGridData.filterData(filterObj,this.trueUpReportData);
  }

  exportExcel() {
    let displayedContractJson: any[] = [];
    for (let originalValue of this.trueUpReportData) {
     let jsonObject = Object.assign({}, originalValue);
      jsonObject.Contract_id = undefined;
      jsonObject.UpfrontPayment = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.UpfrontPayment);
      jsonObject.MPR = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.MPR);
      jsonObject.MPRActualTODate = this.contractCalculation.commafy(jsonObject.MPRActualTODate);
      jsonObject.FixedRebate = this.contractCalculation.commafy(jsonObject.FixedRebate);
      jsonObject.VariableRebate = this.contractCalculation.commafy(jsonObject.VariableRebate);
      jsonObject.trueup_amount = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.trueup_amount);
      jsonObject.trueup_cumulative = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.trueup_cumulative);
      jsonObject.TrueUpPer = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.TrueUpPer);
      jsonObject.ContractDuration = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.ContractDuration);
      jsonObject.MPRReqToDate = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.MPRReqToDate);      
     let jsonString = JSON.stringify(jsonObject);
     displayedContractJson.push(JSON.parse(jsonString));
    }
    this._exportExcel.exportAsExcelFile(displayedContractJson, `TrueUpReport${new Date()}`, 'sheet1', this.trueUpGridCol);
  }

  onSelectRow(rowObject) {
    this.router.navigate(['Contracts/contracts-details'], { state: { contractId: rowObject.rowData.Contract_id } });
  }

}
