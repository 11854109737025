import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { Observable, Subject } from 'rxjs';
import { Contractobj } from '../app/interfaces/contractobj';
import { WdNotifyEmail } from '../app/interfaces/wdNotifyEmail';
import { UserDetail } from 'src/app/interfaces/userDetail';

@Injectable({
  providedIn: 'root'
})

export class ContractPerfService {

  private userSubject = new Subject<any>();
  private nav_menuSubject = new Subject<boolean>();


  userName: string;
  constructor(private _http: HttpClient) {
    this.userName = sessionStorage.getItem('userId');
  }

  //Service to import contracts and sales excel data
  importExcelFile(excelFileData: FormData, uploadFileType: string) {
    if (uploadFileType == 'Contracts') {
      return this._http.post(`${environment.importExcel}?userName=${this.userName}`, excelFileData, { responseType: 'text' }).pipe(map((response) => response))
    }
    else if (uploadFileType == 'Sales') {
      return this._http.post(`${environment.importSalesExcel}?userName=${this.userName}`, excelFileData, { responseType: 'text' }).pipe(map((response) => response))
    }
  }

  sendContractPDFEmail(contractPdfData: FormData, contractId: number, emails: string) {
    return this._http.post(`${environment.emailContractPDFData}?Emails=${emails}&ContractId=${contractId}&userName=${this.userName}`, contractPdfData, { responseType: 'text' }).pipe(map((response) => response))
  }

  //Service to get all contracts data
  getAllContractData() {
    return this._http.get(`${environment.getAllContracts}?userName=${this.userName}`).pipe(map((response) => response));
  }

  //Service to get all sales data
  getSalesData(contractId, selectedYear) {
    return this._http.get(`${environment.getSalesData}?ContractNo=${contractId}&userName=${this.userName}&selectedYear=${selectedYear}`).pipe(map((response) => response));
  }

  //service to get detail of specific contract detail
  getContractDetailData(ContractId: number): Observable<Contractobj> {
    return this._http.get<Contractobj>(`${environment.getContractDetail}?ContractId=${ContractId}&userName=${this.userName}`).pipe(map((response) => response));
  }

  //service to initialise the contract screen
  getCreateContractDetailData(): Observable<Contractobj> {
    return this._http.get<Contractobj>("./assets/CreateContract.json")
  }

  //service of get all master data
  getMasterData(): Observable<any> {
    return this._http.get<any>(`${environment.getMasterTable}?userName=${this.userName}`).pipe(map((response) => response));
  }

  //service of save contract data
  saveContractData(ContractData: Contractobj, contractId: number): Observable<any> {
    return this._http.post(`${environment.saveContracts}?contractId=${contractId}&userName=${this.userName}`, ContractData, { responseType: 'text' }).pipe(map((response) => response))
  }

  downloadCommentfile(fileId: number) {
    return this._http.get<any>(`${environment.downloadCommentfile}?fileId=${fileId}&userName=${this.userName}`).pipe(map((response) => response));
  }

  wdNotifyContract(wdNotifyEmail: WdNotifyEmail): Observable<any> {
    return this._http.post(`${environment.sendEmailwdNotify}?contractId=${wdNotifyEmail.contractId}&Status=${wdNotifyEmail.status}&userName=${this.userName}`, wdNotifyEmail.emails, { responseType: 'text' }).pipe(map((response) => response))
  }

  getTrueUpReport(Year, Month) {
    console.log('trueup' + this.userName);
    return this._http.get(`${environment.getTrueUpReport}?Year=${Year}&Month=${Month}&userName=${this.userName}`).pipe(map((response) => response));
  }

  getMissingPurchaseReport() {
    return this._http.get(`${environment.getMissingPurchaseReport}?userName=${this.userName}`).pipe(map((response) => response));
  }

  getBoardReport(Year, Month, EYear, EMonth) {
    return this._http.get(`${environment.getBoardReport}?Year=${Year}&Month=${Month}&EYear=${EYear}&EMonth=${EMonth}&userName=${this.userName}`).pipe(map((response) => response));
  }

  getGridHeader(Name) {
    return this._http.get<any>(`${environment.getGridHeader}?fornName=${Name}&userName=${this.userName}`).pipe(map((response) => response));
  }

  getSAMLService() {
    return this._http.get(environment.getSAMLURL).pipe(map((response) => response));
  }

  getDecryptedUserDetails(data) {
    return this._http.get(`${environment.decryptUserDetails}${data}`).pipe(map((response) => response));
  }

  getUserInfo(data) {
    return this._http.get(`${environment.getUserInfo}${data}`).pipe(map((response) => response));
  }


  setNavmenustatus(status: boolean) {
    this.userName = sessionStorage.getItem('userId');
    this.nav_menuSubject.next(status);
  }

  getNavmenustatus(): Observable<boolean> {
    return this.nav_menuSubject.asObservable();
  }

  getUserRole() {
    return this._http.get(`${environment.getUserRole}?userName=${this.userName}`).pipe(map((response) => response));
  }

  getUserDetail(emailaddress) {
    return this._http.get<any>(`${environment.getUserDetails}?email=${emailaddress}&userName=${this.userName}`).pipe(map((response) => response));
  }

  getUsers() {
    return this._http.get<any>(`${environment.getUsers}?userName=${this.userName}`).pipe(map((response) => response));
  }

  saveUserDetail(userDetail: UserDetail): Observable<any> {
    return this._http.post(`${environment.saveUser}?userName=${this.userName}`, userDetail).pipe(map((response) => response))
  }

  deleteUser(userId): Observable<any> {
    return this._http.delete(`${environment.deleteUser}?userId=${userId}&&userName=${this.userName}`).pipe(map((response) => response))
  }

  getMasterReport() {
    return this._http.get(`${environment.getMasterReport}?userName=${this.userName}`).pipe(map((response) => response));
  }

  getCustPerfReport(customerName, contractNumber): Observable<any> {
    if (contractNumber == undefined || contractNumber == "") {
      contractNumber = 0;
    }
    return this._http.get(`${environment.ContractPerfReport}?contractNumber=${contractNumber}&customerName=${customerName}&userName=${this.userName}`).pipe(map((response) => response))
  }
  getExpiringContract(year, quarter): Observable<any> {
    if (quarter == undefined) {
      quarter = 0;
    }
    return this._http.get(`${environment.getExpiringContract}?Year=${year}&Quarter=${quarter}&userName=${this.userName}`).pipe(map((response) => response))
  }

  getMissingStatusReasonReport() {
    return this._http.get(`${environment.getMissingContractStatusReasonReport}?userName=${this.userName}`).pipe(map((response) => response));
  }

  getMPRReport() {
    return this._http.get(`${environment.getMPRReport}?userName=${this.userName}`).pipe(map((response) => response));
  }

  getZeroPurchaseReport() {
    return this._http.get(`${environment.getZeroPurchaseReport}?userName=${this.userName}`).pipe(map((response) => response));
  }

  getAuditlogs(contractId) {
    return this._http.get(`${environment.getAuditlogs}?ContractId=${contractId}&userName=${this.userName}`).pipe(map((response) => response));
  }

  getIdByContractNumber(contract) {
    return this._http.get(`${environment.getIdByContractNumber}?ContractNumber=${contract}&userName=${this.userName}`).pipe(map((response) => response));
  }

  getSFAccountDetails(accountNumber) {
    return this._http.get(`${environment.getSFAccountDetails}?AccountNumber=${accountNumber}&userName=${this.userName}`).pipe(map((response) => response));
  }
  deleteContact(contractId) {
    return this._http.delete(`${environment.deleteContact}?ContractId=${contractId}&&userName=${this.userName}`).pipe(map((response) => response));
  }

  getWdEmails(contractId) {
    return this._http.get<any>(`${environment.getWdEmails}?ContractId=${contractId}&userName=${this.userName}`).pipe(map((response) => response));
  }

  getMasterKey() {
    return this._http.get(`${environment.getMasterKey}?userName=${this.userName}`).pipe(map((response) => response));
  }

  getMasterValueByKey(masterKey: string) {
    return this._http.get(`${environment.getMasterValueByKey}?masterKey=${masterKey}&userName=${this.userName}`).pipe(map((response) => response));
  }

  updateMaster(masterDate: any) {
    return this._http.put(`${environment.updateMasterData}?userName=${this.userName}`, masterDate, { responseType: 'text' }).pipe(map((response) => response))
  }

  addMaster(masterDate: any) {
    return this._http.post(`${environment.addMasterData}?userName=${this.userName}`, masterDate, { responseType: 'text' }).pipe(map((response) => response))
  }

  getWdEmailList() {
    return this._http.get<any>(`${environment.getWdEmailList}?userName=${this.userName}`).pipe(map((response) => response));
  }

  addWDEmail(wdEmails: any) {
    return this._http.post(`${environment.addWdEmail}?userName=${this.userName}`, wdEmails, { responseType: 'text' }).pipe(map((response) => response))
  }

  deleteWDEmail(id: number) {
    return this._http.delete(`${environment.deleteWDEmail}?Id=${id}&userName=${this.userName}`).pipe(map((response) => response))
  }

  getWdEmailMapping(WdId: number) {
    return this._http.get<any>(`${environment.getWdEmailMapping}?WdId=${WdId}&userName=${this.userName}`).pipe(map((response) => response));
  }

  addWDEmailMapping(wdEmailMappingData: any) {
    return this._http.post(`${environment.addWdEmailMapping}?userName=${this.userName}`, wdEmailMappingData, { responseType: 'text' }).pipe(map((response) => response))
  }

  deleteWDEmailMapping(id: number) {
    return this._http.delete(`${environment.deleteWDEmailMapping}?Id=${id}&userName=${this.userName}`).pipe(map((response) => response))
  }

  deleteLookUp(id: number) {
    return this._http.delete(`${environment.deleteLookUp}?Id=${id}&userName=${this.userName}`).pipe(map((response) => response))
  }
}
