import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpConfigInterceptor } from './_helpers/httpconfig.interceptor';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';

import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './components/home/home.component';
import { DynamicGridComponent } from './components/dynamic-grid/dynamic-grid.component';
import { ImportFileComponent } from './components/import-file/import-file.component';



import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { AccordionModule } from 'primeng/accordion';
import { FileUploadModule } from 'primeng/fileupload';
import { CookieService } from 'ngx-cookie-service';
import { MessageService } from 'primeng/api';
import { DragDropDirective } from 'src/services/directives/drag-drop.directive';
import { ContractComponent } from './components/contract/contract.component';
import { ContractDetailComponent } from './components/contract-detail/contract-detail.component';
import { ExportExcel } from 'src/services/export-excel.model';
import { DialogModule } from "primeng/dialog";
import { TrueUpReportComponent } from './components/reports/true-up-report/true-up-report.component';
import { ContractCalculation } from 'src/services/contract-calculation';
import { MissingPurchaseComponent } from './components/reports/missing-purchase/missing-purchase.component';
import { FiletrGridData } from 'src/services/filterGridData';
import { ReportComponent } from './components/reports/report/report.component';
import { TabViewModule } from 'primeng/tabview';
import { BoardreportComponent } from './components/reports/boardreport/boardreport.component';
import { LoginComponent } from './components/login/login.component';
import { UserdetailsComponent } from './components/admin/userdetails/userdetails.component';
import { PerformanceCustComponent } from './components/reports/performance-cust/performance-cust.component';
import { MasterListComponent } from './components/reports/master-list/master-list.component';
import { ExpiringContractsComponent } from './components/reports/expiring-contracts/expiring-contracts.component';
import { AuthGuard } from './guards/auth-guard.model';
import { FormatamountPipe } from 'src/services/formatamount.pipe';
import { MissingStatusReasonComponent } from './components/reports/missing-status-reason/missing-status-reason.component';
import { MprReportComponent } from './components/reports/mpr-report/mpr-report.component';
import { ZeroPurchaseComponent } from './components/reports/zero-purchase/zero-purchase.component';
import { LookupComponent } from './components/admin/lookup/lookup.component';
import { WdEmailComponent } from './components/admin/wd-email/wd-email.component';
import { WDEmailMappingComponent } from './components/admin/wdemail-mapping/wdemail-mapping.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { NgSelectModule } from '@ng-select/ng-select';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    DynamicGridComponent,
    ImportFileComponent,
    DragDropDirective,
    ContractComponent,
    ContractDetailComponent,
    TrueUpReportComponent,
    MissingPurchaseComponent,
    ReportComponent,
    BoardreportComponent,
    LoginComponent,
    UserdetailsComponent,
    PerformanceCustComponent,
    MasterListComponent,
    ExpiringContractsComponent,
    FormatamountPipe,
    MissingStatusReasonComponent,
    MprReportComponent,
    ZeroPurchaseComponent,
    LookupComponent,
    WdEmailComponent,
    WDEmailMappingComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    AccordionModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      closeButton: true
    }),
    ToastrModule,
    NgxSpinnerModule,
    MultiSelectModule,
    TableModule,
    DropdownModule,
    DialogModule,
    FileUploadModule,
    TabViewModule,
    ReactiveFormsModule,

    AutoCompleteModule,
    NgSelectModule,

    RouterModule.forRoot([
      { path: '', component: LoginComponent, pathMatch: 'full' },
      { path: 'Login', component: LoginComponent },
      { path: 'Home', component: HomeComponent, canActivate: [AuthGuard] },
      { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
      { path: 'Contracts', component: ContractComponent, canActivate: [AuthGuard] },
      { path: 'Contracts/contracts-details', component: ContractDetailComponent, canActivate: [AuthGuard] },
      { path: 'Reports', component: ReportComponent, canActivate: [AuthGuard] },
      { path: 'trueUpReport', component: TrueUpReportComponent, canActivate: [AuthGuard] },
      { path: 'missingPurchaseReport', component: MissingPurchaseComponent, canActivate: [AuthGuard] },
      { path: 'Admin', component: UserdetailsComponent, canActivate: [AuthGuard] },
      { path: 'masterreport', component: MasterListComponent, canActivate: [AuthGuard] },
      { path: 'Lookup', component: LookupComponent, canActivate: [AuthGuard] },
      { path: 'Wdemail', component: WdEmailComponent, canActivate: [AuthGuard] },
      { path: 'WdemailMapping', component: WDEmailMappingComponent, canActivate: [AuthGuard] }
    ], {})
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
    CookieService,
    MessageService,
    ExportExcel,
    ContractCalculation,
    FiletrGridData,
    AuthGuard
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
