import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
enum MonthsValues {
  "Jan" = 1,
  "Feb" = 2,
  "Mar" = 3,
  "Apr" = 4,
  "May" = 5,
  "Jun" = 6,
  "Jul" = 7,
  "Aug" = 8,
  "Sep" = 9,
  "Oct" = 10,
  "Nov" = 11,
  "Dec" = 12,
}
export class ExportExcel {

  exportPerformanceReport(json: any[], excelFileName: string, sheetName: string, columnValues: any[], json2: any[] = [], sheetName2: string = "", columnValues2: any[] = []) {
    let headerValues: string[] = columnValues2.map(header => header.header);
    let displayedJson: any[] = [];
    const numHeaders = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Total Sales', 'MPR'];
    let json2keys = Object.keys(json2[0]);
    for (let originalValue of json2) {
      let obj = {};
      for (let i = 0; i < json2keys.length; i++) {
        if (numHeaders.includes(headerValues[i])) {
          obj[headerValues[i]] = Number(originalValue[json2keys[i]]);
        }
        else {
          obj[headerValues[i]] = originalValue[json2keys[i]];
        }
      }
      displayedJson.push(obj);
    }

    let headerValues2: string[] = columnValues.map(header => header.header);
    let newHead = [];
    headerValues2.forEach(head => {
      newHead.push(head);
    });

    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(displayedJson);
    XLSX.utils.sheet_add_aoa(worksheet, [newHead], { origin: "A" + (json2.length + 3) });
    XLSX.utils.sheet_add_json(worksheet, json, { origin: "A" + (json2.length + 4), skipHeader: true });
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    let range = XLSX.utils.decode_range(worksheet['!ref']);
    for (let C = range.s.c; C <= range.e.c; ++C) {
      let address = XLSX.utils.encode_col(C) + "1"; // <-- first row, column number C
      if (!worksheet[address]) continue;
      worksheet[address] = {
        v: headerValues[C],
        t: 's',
        s: {
          alignment: { textRotation: 90 },
          font: { bold: true }
        }
      };
    }

    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  exportBoardReport(json: any[], excelFileName: string, sheetName: string, columnValues: any[], month, year, emonth, eyear) {
    let dateJson: any[] = [];
    dateJson.push({ From: '', 'Start Month': MonthsValues[month], 'Start Year': year, To: '', 'End Month': MonthsValues[emonth], 'End Year': eyear });
    let headerValues: string[] = columnValues.map(header => header.header);
    let newHead = [];
    headerValues.forEach(head => {
      newHead.push(head);
    });

    const wb = XLSX.utils.book_new();
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dateJson);
    XLSX.utils.sheet_add_aoa(ws, [newHead], { origin: "A4" });
    XLSX.utils.sheet_add_json(ws, json, { origin: "A5", skipHeader: true });
    XLSX.utils.book_append_sheet(wb, ws, sheetName);
    const merge = [
      { s: { r: 0, c: 0 }, e: { r: 1, c: 0 } }, { s: { r: 0, c: 3 }, e: { r: 1, c: 3 } },
    ];
    ws["!merges"] = merge;
    
    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  exportAsExcelFile(json: any[], excelFileName: string, sheetName: string, columnValues: any[]): void {
    let headerValues: string[] = columnValues.map(header => header.header);
    let displayedJson: any[] = [];

    if (json != null && json.length > 0) {
      for (let originalValue of json) {
        let jsonString = JSON.stringify(originalValue);
        displayedJson.push(JSON.parse(jsonString));
      }

      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(displayedJson);
      let range = XLSX.utils.decode_range(worksheet['!ref']);
      for (let C = range.s.c; C <= range.e.c; ++C) {
        let address = XLSX.utils.encode_col(C) + "1"; // <-- first row, column number C
        if (!worksheet[address]) continue;
        worksheet[address] = {
          v: headerValues[C],
          t: 's',
          s: {
            alignment: { textRotation: 90 },
            font: { bold: true }
          }
        };
      }

      XLSX.utils.sheet_add_aoa(worksheet, [[]], { origin: -1 });
      const workbook: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, excelFileName);
    }

  }

  generateSheet2(json2, worksheet2, columnValues2, startLength) {
    let displayedJson2: any[] = [];
    if (json2 != null && json2.length > 0) {
      for (let originalValue of json2) {
        let jsonString2 = JSON.stringify(originalValue);
        displayedJson2.push(JSON.parse(jsonString2));
      }
      let headerValues2: string[] = columnValues2.map(header => header.header);
      const tempGridData = XLSX.utils.json_to_sheet(displayedJson2);
      worksheet2 = Object.assign(worksheet2, tempGridData);
      let range2 = XLSX.utils.decode_range(worksheet2['!ref']);
      for (let D = range2.s.c + startLength; D <= range2.e.c + startLength; ++D) {
        let address2 = XLSX.utils.encode_col(D) + "1"; // <-- first row, column number C
        if (!worksheet2[address2]) continue;
        worksheet2[address2] = {
          v: headerValues2[D],
          t: 's',
          s: {
            alignment: { textRotation: 90 },
            font: { bold: true }
          }
        };
      }
    }
    return worksheet2;
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }
}
