import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ContractPerfService } from 'src/services/contract-perf.service';
import { FilterService } from 'primeng/api';
@Component({
  selector: 'app-dynamic-grid',
  templateUrl: './dynamic-grid.component.html',
  styleUrls: ['./dynamic-grid.component.css'],
  encapsulation:ViewEncapsulation.None
})
export class DynamicGridComponent implements OnInit {
  @Input() gridCols: any[];
  @Input() gridData: any;
  @Input() checkboxShow: any;
  @Input() showEdit: any;
  @Input() showView: any;
  @Input() showDelete: any;
  @Input() ShowDeleteContract: any;
  @Input() ShowDeleteLookUp: any;
  @Input() showFilter: any;
  @Output() filterData = new EventEmitter();
  @Output() selectRow = new EventEmitter();
  @Output() sendSelectedRows = new EventEmitter();
  @Input() isCommentGrid = false;
  cupsRequestData: any;
  apiResp: any;
  defaultWidth: '100%';
  userId: any;
  userAdmin: boolean = false;
  filteredData: any;
  filteredFields = [];
  tempFilteredData: any;
  title = "Cups Request";
  selectedRows: any;
  
  constructor(private _service: ContractPerfService, private router: Router,private spinner: NgxSpinnerService) { console.log(this.gridCols);}

  ngOnInit() {

    console.log(this.gridCols)
    //FilterUtils['custom'] = (value, filter): boolean => {
    //  if (filter === undefined || filter === null || filter.trim() === '') {
    //    return true;
    //  }

    //  if (value === undefined || value === null) {
    //    return false;
    //  }

    //  return parseInt(filter) > value;
    //}
  }

  sendFilterData(value, filter) {
    let filterObj = {
      value, filter
    };
    this.filterData.emit(filterObj);
  }

  onSelectRow(rowData, type) {
    let rowObject = {rowData, type};
    this.selectRow.emit(rowObject);
  }

  onDocumentClick(rowData) {
    let documentRowData = {rowData};
    this.selectRow.emit(documentRowData);
  }

  checkSelectedRow(event) {
    this.sendSelectedRows.emit(this.selectedRows)
  }
}
