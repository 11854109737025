import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ContractPerfService } from '../../../../services/contract-perf.service';
import { FiletrGridData } from '../../../../services/filterGridData';


@Component({
  selector: 'app-lookup',
  templateUrl: './lookup.component.html',
  styleUrls: ['./lookup.component.css']
})

export class LookupComponent implements OnInit {
  masterKey: any;
  lookUpKeyddr: string = 'Agreement Type';
  masterValues: any;
  MasterCols: any;
  masterData: UntypedFormGroup;
  addNewFlag: boolean = false;
  updateBtFlag: boolean = true;
  displayDeleteLookUp = false;
  modelon = false;
  selectedLookUpId: any;
  addBtFlag: boolean = false;
  constructor(private contractPerfService: ContractPerfService, private spinner: NgxSpinnerService, private filetrGridData: FiletrGridData, private toastr: ToastrService, private fb: UntypedFormBuilder) {
    this.masterData = fb.group({
      masterKey: new UntypedFormControl('', Validators.required),
      keyId: new UntypedFormControl(0, Validators.required),
      keyValue: new UntypedFormControl({ value: '', disabled: true }, Validators.required)
    });
  }

  ngOnInit() {
    this.MasterCols = [
      { field: 'masterKey', header: 'Master ID', is_show: true, isEditable: false, serialNo: 1 },
      { field: 'keyId', header: 'Master Key ID', is_show: true, isEditable: false, serialNo: 2 },
      { field: 'keyValue', header: 'Value', is_show: true, isEditable: true, serialNo: 3 },
    ]
    this.GetMasterKey();
    this.GetLookUp();
  }

  GetLookUp() {
    this.masterData.reset();
    this.masterData.get('keyId').setValue(0);
    if (this.lookUpKeyddr) {
      this.spinner.show();
      this.contractPerfService.getMasterValueByKey(this.lookUpKeyddr).subscribe(res => {
        if (res) {
          this.masterValues = res;
        }
        this.spinner.hide();
      });
    }
  }

  deleteLookUp() {
    this.spinner.show();
    this.contractPerfService.deleteLookUp(this.selectedLookUpId).subscribe(
      response => {
        this.spinner.hide();
        this.toastr.success("LookUp deleted succesfully.")
        this.displayDeleteLookUp = false;
        this.modelon = false;
        this.GetLookUp();
      },
      error => {
        this.spinner.hide();
        this.toastr.error("Error Occurred")
        this.displayDeleteLookUp = false;
        this.modelon = false;
      });
  }

  closeConfirmationpopUp() {
    this.displayDeleteLookUp = false;
    this.modelon = false;
  }

  UpdateLookUp() {
    if (!this.masterData.invalid) {
      this.spinner.show();
      this.contractPerfService.updateMaster(this.masterData.value).subscribe(res => {
        if (res) {
          this.toastr.success("Updated Successfully!");
          this.GetLookUp();
          this.spinner.hide();
          this.updateBtFlag = true;
          this.masterData.get('keyValue').setValue('');
          this.masterData.get('keyValue').disable();
        }
      });

    }
  }

  GetMasterKey() {
    this.contractPerfService.getMasterKey().subscribe(res => {
      if (res) {
        this.masterKey = res;
      }
    });
  }
  onSelectRow(rowObject) {

    let rowData = rowObject;
    if (rowData.type == 'deleteLookUp') {
      this.displayDeleteLookUp = true;
      this.modelon = true;
      this.selectedLookUpId = rowData.rowData.masterKey;
    }
    else if (rowData.type == 'view') {
      this.updateBtFlag = false;
      this.masterData.get('keyValue').enable();
      this.masterData.patchValue(rowData.rowData);
    }
  }

  filterData(filterObj) {
    this.filetrGridData.filterData(filterObj, this.masterValues);
  }

  AddNew() {
    this.addNewFlag = true;
    this.masterValues = null;
    this.masterData.get('keyValue').enable();
    this.masterData.get('keyValue').setValue('');
  }

  Cancel() {
    this.addNewFlag = false;
    this.masterData.get('keyValue').reset();
    this.GetLookUp();
    this.masterData.get('keyValue').disable();
    this.updateBtFlag = true;
    this.addBtFlag = false;
  }

  AddLookUp() {
    if (!this.lookUpKeyddr || this.masterData.get('keyValue').value == '' || !this.masterData.get('keyValue').value) {
      this.addBtFlag = true;
      return;
    }

    let data = {
      keyValue: this.masterData.get('keyValue').value,
      masterKey: this.lookUpKeyddr
    };
    this.spinner.show();
    this.contractPerfService.addMaster(data).subscribe(res => {
      if (res) {
        this.toastr.success("Added Successfully!");
        this.GetLookUp();
        this.spinner.hide();
        this.addNewFlag = false;
        this.masterData.get('keyValue').disable();
        this.addBtFlag = false;
      }
    });


  }

}
