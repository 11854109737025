import { Component } from '@angular/core';
import { ContractPerfService } from 'src/services/contract-perf.service';
import { ExportExcel } from 'src/services/export-excel.model';
import { ContractCalculation } from 'src/services/contract-calculation';
import { FiletrGridData } from 'src/services/filterGridData';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-expiring-contracts',
  templateUrl: './expiring-contracts.component.html'
})

export class ExpiringContractsComponent {

  expiringContractGridCol: any[]
  expiringContractGridData: any
  todayDate: any;
  selectedQuarter:number
  selectedYear:number
  contractQuarter: any = [{ value: " ", key: 0 },{value:"Q1",key:1},{value:"Q2",key:2},{value:"Q3",key:3},{value:"Q4",key:4}]
  expiringContractYear:any = [{value:2010, id:2010}]
  constructor(private contractPerfService: ContractPerfService,private spinner: NgxSpinnerService,private toastr: ToastrService,private router: Router, private _exportExcel: ExportExcel, private contractCalculation:ContractCalculation, private filetrGridData: FiletrGridData) { }

  ngOnInit() {
    this.todayDate= this.filetrGridData.getTodaysDate();
    this.getGridHeader();
    this.expiringContractYear = this.filetrGridData.getYear();
    this.selectedQuarter = 0;
    this.selectedYear = this.filetrGridData.getCurrentMonthYear().selectedYear;
    this.bindExpiringContractGrid();
  }

 
  getGridHeader()
  {
    this.contractPerfService.getGridHeader('ExpiringContract').subscribe(
      response => {
        this.expiringContractGridCol = response; 
      },
      error => {
        this.toastr.error('Error Occurred');
      });
  }
 
  
  bindExpiringContractGrid() {
    this.spinner.show();
    if (this.selectedYear.toString() == 'undefined') {
      this.selectedYear = 2010;
    }
    this.contractPerfService.getExpiringContract(this.selectedYear, this.selectedQuarter).subscribe(
      response => {
        this.expiringContractGridData = response;
        this.expiringContractGridData.forEach((element) => {
          element.MPRReqDt = this.contractCalculation.calculateMPRtoDate(element.upfrontPmt, element.ContDuration, element.ValidFrom,this.todayDate , 1, true);
          element.ContBalanceMPR = this.contractCalculation.calculatecontractMPRBalance(element.MPR, element.MPRActualTODate);
          element.upfrontPmt = Math.trunc(element.upfrontPmt);
          element.upfrontPmt = this.contractCalculation.commafy(element.upfrontPmt);
          element.MPRReqDt = this.contractCalculation.commafy(element.MPRReqDt);
          element.MPR = Math.trunc(element.MPR);
          element.MPR = this.contractCalculation.commafy(element.MPR);
          element.FixedRebate = Math.trunc(element.FixedRebate);
          element.VarRebate = Math.trunc(element.VarRebate);
        });
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
        this.toastr.error('Error Occurred');
      });
  }

 


  filterData(filterObj) {
    this.filetrGridData.filterData(filterObj,this.expiringContractGridData);
  }

  exportExcel() {
    let displayedContractJson: any[] = [];
    for (let originalValue of this.expiringContractGridData) {
     let jsonObject = Object.assign({}, originalValue);
     jsonObject.Contract_id = undefined;
      jsonObject.ActualPaintPurchase = undefined;
      jsonObject.ContBalanceMPR = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.ContBalanceMPR);
      jsonObject.ContDuration = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.ContDuration);
      jsonObject.FixedRebate = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.FixedRebate);
      jsonObject.MPR = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.MPR);
      jsonObject.MPRActualTODate = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.MPRActualTODate);
      jsonObject.MPRPer = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.MPRPer);
      jsonObject.MPRReqDt = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.MPRReqDt);
      jsonObject.VarRebate = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.VarRebate);
      jsonObject.upfrontPmt = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.upfrontPmt);
     let jsonString = JSON.stringify(jsonObject);
     displayedContractJson.push(JSON.parse(jsonString));
    }
    this._exportExcel.exportAsExcelFile(displayedContractJson, `Expiring_Contracts${new Date()}`, 'sheet1', this.expiringContractGridCol);
  }

  onSelectRow(rowObject) {
    this.router.navigate(['Contracts/contracts-details'], { state: { contractId: rowObject.rowData.Contract_id } });
  }

}
