import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ContractCalculation } from '../../../../services/contract-calculation';
import { ContractPerfService } from '../../../../services/contract-perf.service';
import { ExportExcel } from '../../../../services/export-excel.model';
import { FiletrGridData } from '../../../../services/filterGridData';

@Component({
  selector: 'app-mpr-report',
  templateUrl: './mpr-report.component.html'
})
export class MprReportComponent implements OnInit {
  mprReportGridCol: any[]
  mprReportGridData: any
  constructor(private contractPerfService: ContractPerfService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private router: Router,
    private _exportExcel: ExportExcel,
    private filetrGridData: FiletrGridData,
    private contractCalculation: ContractCalculation) { }

  ngOnInit() {
    this.getmprReportGridHeader();
    this.bindmprReportGrid();
  }

  getmprReportGridHeader() {
    this.contractPerfService.getGridHeader('MprReport').subscribe(
      response => {
        this.mprReportGridCol = response;
      },
      error => {
        this.toastr.error('Error Occurred');
      });
  }


  bindmprReportGrid() {
    this.spinner.show();
    this.contractPerfService.getMPRReport().subscribe(
      response => {
        this.mprReportGridData = response;
        this.spinner.hide();
      },
      error => {
        this.toastr.error('Error Occurred');
        this.spinner.hide();
      });
  }

  filterData(filterObj) {
    this.filetrGridData.filterData(filterObj, this.mprReportGridData)
  }

  exportExcel() {
    let displayedContractJson: any[] = [];
    for (let originalValue of this.mprReportGridData) {
      let jsonObject = Object.assign({}, originalValue);
      jsonObject.Contract_id = undefined;
      //jsonObject.PercentageMPR = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.PercentageMPR);
      jsonObject.MPJan = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.MPJan);
      jsonObject.MPFeb = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.MPFeb);
      jsonObject.MPMar = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.MPMar);
      jsonObject.MPApr = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.MPApr);
      jsonObject.MPMay = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.MPMay);
      jsonObject.MPJun = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.MPJun);
      jsonObject.MPJul = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.MPJul);
      jsonObject.MPAug = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.MPAug);
      jsonObject.MPSep = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.MPSep);
      jsonObject.MPOct = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.MPOct);
      jsonObject.MPNov = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.MPNov);
      jsonObject.MPDec = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.MPDec);
      jsonObject.MPTotalSales = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.MPTotalSales);
      let jsonString = JSON.stringify(jsonObject);
      displayedContractJson.push(JSON.parse(jsonString));
    }
    this._exportExcel.exportAsExcelFile(displayedContractJson, `MPRReport${new Date()}`, 'sheet1', this.mprReportGridCol)
  }

  onSelectRow(rowObject) {
    this.router.navigate(['Contracts/contracts-details'], { state: { contractId: rowObject.rowData.Contract_id } });
  }

}
