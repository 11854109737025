import { Component, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';
import { ContractPerfService } from 'src/services/contract-perf.service';
import { MessageService } from 'primeng/api';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-import-file',
  templateUrl: './import-file.component.html',
  styleUrls: ['./import-file.component.css']
})


export class ImportFileComponent {

  fileUploadMessage: string;
  uploadedFiles: any;
  fileName = "";
  fileType = "";
  fileUpload = false;
  filesize = "";
  fileBuffer: any;
  fileObject: any;
  displayUpload: boolean = false;
  importMessage: string;
  uploadFileType: string;
  @ViewChild('fileInput', { static: false }) fileobj: ElementRef;
  @Output() fileUploadStatus = new EventEmitter();

  constructor(private service: ContractPerfService, private messageService: MessageService, private spinner: NgxSpinnerService, private toastr: ToastrService) { }



  //Display the file Upload Popup, event is called from HomeComponent
  diaplayUpload(fileType) {
    this.uploadFileType = fileType;
    this.displayUpload = true;
  }

  //Close the file upload popup and clear files
  closepopUp() {
    this.displayUpload = false;
    this.fileName = "";
    this.fileType = "";
    this.filesize = "";
    this.fileobj.nativeElement.value = "";
    this.uploadedFiles = "";
  }

  // file uploded on the client side
  myUploader(event) {
    this.spinner.show();
    let fileData = event[0];
    this.filesize = fileData.size.toString();
    if (fileData.size > 3145728) {
      this.spinner.hide();
      this.toastr.warning("File Limit Exceeded, Allows only below 3MB data");
      return false;
    }
    else {
      this.fileName = fileData.name;
      this.fileType = fileData.type;
    }
    this.uploadedFiles = event[0];
    this.spinner.hide();
  }

  //On click of file Submit and to make BE Api call
  uploadSubmit() {

    this.spinner.show();
    if (this.uploadedFiles.length == 0) {
      this.spinner.hide();
      this.toastr.warning("Please select file to Upload");
      return;
    }
    this.uploadedFiles = <File>this.uploadedFiles;
    const formData: FormData = new FormData();
    formData.append('file', this.uploadedFiles, this.uploadedFiles.name);
    this.service.importExcelFile(formData, this.uploadFileType).subscribe(
      response => {

        this.DisplayFileUploadNotification(response);
        this.uploadedFiles = "";
        this.spinner.hide();
      }, error => {

        this.fileUploadMessage = "Error"
        this.toastr.error('Error in file upload');
        this.uploadedFiles = "";
        this.spinner.hide();
      });

    this.displayUpload = false;
    this.removeFile();
  }

  //File upload Notifications
  DisplayFileUploadNotification(message) {
    this.fileUploadMessage = message;
    if (this.uploadFileType == 'Contracts') {
      if (this.fileUploadMessage == 'Contracts imported successfully') {
        this.toastr.success('All Contracts Uploaded Successfully');
      }
      else {
        if (this.fileUploadMessage.includes("ErrorMessage")) {
          this.toastr.error(this.fileUploadMessage);
        } else {
          this.toastr.warning('Contracts Uploaded Successfully except below duplicate Contracts: ' + this.fileUploadMessage.split("|")[1], '', { closeButton: true, timeOut: 0 });
        }
      }
      this.fileUploadStatus.emit(this.fileUploadMessage);
    }
    else if (this.uploadFileType == 'Sales') {

      if (this.fileUploadMessage == 'Sales data imported successfully') {
        this.toastr.success('Sales Data Uploaded Successfully');
      }
      else {
        this.toastr.warning('Sales Record Uploaded Successfully except below combination of (Contract Number + CRM Number Sales Record): ' + this.fileUploadMessage.split("|")[1], '', { closeButton: true, timeOut: 0 });
      }
    }

  }

  removeFile() {
    this.fileName = "";
    this.fileType = "";
    this.filesize = "";
    this.fileobj.nativeElement.value = "";
    this.uploadedFiles = "";
  }
}
