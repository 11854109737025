import { Component } from '@angular/core';
import { ContractPerfService } from 'src/services/contract-perf.service';
import { ExportExcel } from 'src/services/export-excel.model';
import { ContractCalculation } from 'src/services/contract-calculation';
import { FiletrGridData } from 'src/services/filterGridData';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-performance-cust',
  templateUrl: './performance-cust.component.html'
})

export class PerformanceCustComponent {

  contractNumber: any
  customerName: any = ""
  customerPerHeader: any
  customerPerData: any
  todayDate: any;
  mprReqToDataAmt: any;
  shortFall:any;
  mprPer:any;
  shortfallPer:any;
  shortfallAmt: any;
  contractMPRBalance: any;
  variableRebate: any;
  fixedRebate: any;
  contractperfData: any = [{ "Val1": "", "Val2": "", "Val3": "" }];
  salesCols = [
  { header: 'Customer Purchases' },
  { header: 'Year' },
  { header: 'Amount' }]

  constructor(private contractPerfService: ContractPerfService,private spinner: NgxSpinnerService,private toastr: ToastrService, private router: Router, private _exportExcel: ExportExcel, private contractCalculation: ContractCalculation, private filetrGridData: FiletrGridData) { }

  ngOnInit() {
    this.todayDate= this.filetrGridData.getTodaysDate();
    this.getCustomerPerGridHeader();
  }

  getCustomerPerGridHeader() {
    this.contractPerfService.getGridHeader('CustomerPerf').subscribe(
      response => {
        this.customerPerHeader = response;
      },
      error => {
        this.toastr.error('Error Occurred');
      });
  }

  bindCustomerPerGrid() {
    this.contractperfData = [];
    this.spinner.show()
    this.contractPerfService.getCustPerfReport(this.customerName, this.contractNumber).subscribe(
      response => {
        this.customerPerData = response;
        if (this.customerPerData.length <= 0) {
          this.toastr.error('No Data found');
        }
        else
        {
          
          this.mprReqToDataAmt = this.contractCalculation.calculateMPRtoDate(this.customerPerData[0].MPR, this.customerPerData[0].ContractTerm, this.customerPerData[0].ValidFrom,this.todayDate , 1, true);
          this.shortFall = (Number(this.customerPerData[0].ActualPaintPurchase) - Number(this.mprReqToDataAmt)).toFixed(2) ;
          this.mprPer = this.mprReqToDataAmt != 0 ? ((Number(this.customerPerData[0].ActualPaintPurchase) / Number(this.mprReqToDataAmt))*100).toFixed(2) : 0;
          this.shortfallPer = (100-this.mprPer).toFixed(2);
          this.shortfallAmt = ((Number(this.customerPerData[0].MarketInvestment) * Number(this.shortfallPer))/ 100).toFixed(2);
          this.contractMPRBalance = (Number(this.customerPerData[0].MPR) - (Number(this.customerPerData[0].ActualPaintPurchase))).toFixed(2);
          this.variableRebate = this.customerPerData[0].VariableRebate;
          this.fixedRebate = this.customerPerData[0].FixedRebate;
          this.customerPerData[0].MarketInvestment = Math.trunc(this.customerPerData[0].MarketInvestment);
          this.customerPerData[0].MarketInvestment = this.contractCalculation.commafy(this.customerPerData[0].MarketInvestment);
          this.mprReqToDataAmt = this.contractCalculation.commafy(this.mprReqToDataAmt);
          this.variableRebate = Math.trunc(this.variableRebate);
          this.fixedRebate = Math.trunc(this.fixedRebate);
          this.shortFall = this.contractCalculation.commafy(this.shortFall);
          this.shortfallAmt = this.contractCalculation.commafy(this.shortfallAmt);
          this.contractMPRBalance = this.contractCalculation.commafy(this.contractMPRBalance);
        }
      
        if (this.customerPerData.length > 0) {
          this.PerformanceDataJson(this.customerPerData[0].CustomerPurchase, this.customerPerData[0].ActualPaintPurchase, this.customerPerData[0].MarketInvestment);
        }
        this.spinner.hide()
      },
      error => {
        this.toastr.error('Error Occurred');
        this.spinner.hide()
      });
  }

  PerformanceDataJson(YearData, AP,MI) {
    for (let yeardata of YearData) {
      this.contractperfData.push({
        "Val1": "",
        "Val2": yeardata.Year,
        "Val3": yeardata.Amount
      })
    }

    this.contractperfData.push({
      "Val1": "MPR Actual to Date", "Val2": "", "Val3": "$ " + AP
    })
    this.contractperfData.push({
      "Val1": "MPR Required to Dt", "Val2": "", "Val3": "$ " + this.mprReqToDataAmt
    })
    this.contractperfData.push({
      "Val1": "Shortfall/Surplus", "Val2": "", "Val3": "$ " + this.shortFall
    })

    this.contractperfData.push({
      "Val1": "% of MPR", "Val2": "", "Val3": this.mprPer + "%"
    })
    if (MI != '' && MI != 0) {
      this.contractperfData.push({
        "Val1": "Upfront MI", "Val2": "", "Val3": "$ " +  MI
      })
      this.contractperfData.push({
        "Val1": "Shortfall Payment", "Val2": this.shortfallPer + "%", "Val3": this.shortfallAmt + "+ Taxes"
      })
    }
   
    if (this.variableRebate != '' && this.fixedRebate != '') {
      this.contractperfData.push({
        "Val1": "Contract Balance MPR", "Val2": "", "Val3": "$ " + this.contractMPRBalance
      })
      this.contractperfData.push({
        "Val1": "Variable Rebate", "Val2": "", "Val3": "$ " + this.variableRebate
      })
      this.contractperfData.push({
        "Val1": "Fixed Rebate", "Val2": "", "Val3": "$ " + this.fixedRebate
      })
    }
    

  }



  filterData(filterObj) {
    this.filetrGridData.filterData(filterObj, this.customerPerData);
  }

  exportExcel() {
    let displayedContractJson: any[] = [];
    for (let originalValue of this.customerPerData) {
      let jsonObject = Object.assign({}, originalValue);
      jsonObject.Contract_id = undefined;
      jsonObject.MarketInvestment = undefined;
      jsonObject.ActualPaintPurchase = undefined;
      jsonObject.ContractTerm = undefined;
      jsonObject.VariableRebate = undefined;
      jsonObject.FixedRebate = undefined;
      jsonObject.CustomerPurchase = undefined;
      jsonObject.CPJan = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.CPJan);
      jsonObject.CPFeb = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.CPFeb);
      jsonObject.CPMar = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.CPMar);
      jsonObject.CPApr = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.CPApr);
      jsonObject.CPMay = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.CPMay);
      jsonObject.CPJun = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.CPJun);
      jsonObject.CPJul = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.CPJul);
      jsonObject.CPAug = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.CPAug);
      jsonObject.CPSep = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.CPSep);
      jsonObject.CPOct = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.CPOct);
      jsonObject.CPNov = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.CPNov);
      jsonObject.CPDec = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.CPDec);
      jsonObject.CPTotalSales = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.CPTotalSales);
      jsonObject.ActualPaintPurchase = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.ActualPaintPurchase);
      jsonObject.CPYear = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.CPYear);
      jsonObject.ContractDuration = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.ContractDuration);
      jsonObject.ContractTerm = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.ContractTerm);
      jsonObject.FixedRebate = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.FixedRebate);
      jsonObject.MPR = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.MPR);
      jsonObject.MarketInvestment = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.MarketInvestment);
      jsonObject.VariableRebate = this.contractCalculation.convertStringWithCommatoNumber(jsonObject.VariableRebate);
      let jsonString = JSON.stringify(jsonObject);
      displayedContractJson.push(JSON.parse(jsonString));
    }
    this.contractperfData.forEach(res => {
      res.Val2 = this.contractCalculation.convertStringWithCommatoNumber(res.Val2);
      res.Val3 = this.contractCalculation.convertStringWithCommatoNumber(res.Val3);
    })
    this._exportExcel.exportPerformanceReport(this.contractperfData, `CustPerf${new Date()}`, 'sheet1', this.salesCols, displayedContractJson, "sheet2", this.customerPerHeader);
  }

  onSelectRow(rowObject) {
    this.router.navigate(['Contracts/contracts-details'], { state: { contractId: rowObject.rowData.Contract_id } });
  }

}
