import { Component } from '@angular/core';
import { ContractPerfService } from 'src/services/contract-perf.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { UserDetail } from 'src/app/interfaces/userDetail';
import { FiletrGridData } from 'src/services/filterGridData';

@Component({
  selector: 'app-userdetails',
  templateUrl: './userdetails.component.html',
  styleUrls: ['./userdetails.component.css']
})
export class UserdetailsComponent {

  userRole: any;
  selectedRole: number = 1;
  selectedemail: string
  userDetail: UserDetail = {
    userId: 0,
    firstName: "",
    userName: "",
    lastName: "",
    emailId: "",
    isActive: true,
    roleId: 1,
    modifiedBy: "1"
  };
  userData: any;
  UserDetailCols: any;

  constructor(private contractPerfService: ContractPerfService, private spinner: NgxSpinnerService, private filetrGridData: FiletrGridData, private toastr: ToastrService) { }

  ngOnInit() {
    this.UserDetailCols = [
      { field: 'firstName', header: 'First Name', is_show: true, isEditable: false, serialNo: 1 },
      { field: 'lastName', header: 'Last Name', is_show: true, isEditable: true, serialNo: 2 },
      { field: 'userName', header: 'UserName', is_show: true, isEditable: false, serialNo: 3 },
      { field: 'emailId', header: 'Email', is_show: true, isEditable: false, serialNo: 4 },
      { field: 'roleName', header: 'User Role', is_show: true, isEditable: false, serialNo: 5 }
    ]
    this.getUserRoleData();
    this.getUsers();
  }
  getUserRoleData() {
    this.contractPerfService.getUserRole().subscribe(
      response => {
        this.userRole = response;
      },
      error => {
        this.toastr.error("Error Occurred")
      });
  }

  getUsers() {
    this.contractPerfService.getUsers().subscribe(
      response => {
        this.userData = response;
      },
      error => {
        this.toastr.error("Error Occurred")
      });

  }

  GetUserDetails() {
    
    if(this.selectedemail != '' && this.selectedemail != undefined)
    {
      this.spinner.show();
      this.contractPerfService.getUserDetail(this.selectedemail).subscribe(
        response => {
          
          this.userDetail.userId = response.UserId;
          this.userDetail.userName = response.UserName;
          this.userDetail.firstName = response.FirstName;
          this.userDetail.lastName = response.LastName;
          this.userDetail.emailId = response.Email;
          this.userDetail.roleId = this.selectedRole;
          this.userDetail.isActive = true;
          this.userDetail.modifiedBy = "1";
          this.spinner.hide()
        },
        error => {
          this.toastr.error("Error Occurred")
          this.spinner.hide()
        });
    }
    else{
      this.toastr.error("Please select valid email address")
    }
   
  }

  filterData(filterObj) {
    this.filetrGridData.filterData(filterObj, this.userData);
  }

  RegisterUser() {
    this.spinner.show();
    this.userDetail.roleId = Number(this.selectedRole);
    this.contractPerfService.saveUserDetail(this.userDetail).subscribe(
      response => {
        
        this.spinner.hide();
        if(response == "User Already exist")
        {
          this.toastr.warning("User already Exist.")
        }
        else
        {
          this.toastr.success("User added successfully")
          this.getUsers();
          this.resetUserdetail();
        } 
      },
      error => {
        this.spinner.hide();
        this.toastr.error("Error Occurred")
      });
  }

  
  onSelectRow(rowObject) {
    
    let rowData = rowObject;
    if(rowData.type == 'delete')
    {
      this.deleteUser(rowData.rowData.userId,rowData.rowData.userName)
    }
  }

  resetUserdetail(){
    this.userDetail.userId = 0;
    this.userDetail.firstName = "";
    this.userDetail.lastName = "";
    this.userDetail.userName = "";
    this.userDetail.emailId = "";
    this.userDetail.roleId = 1;
    this.selectedemail = "";
  }
 

  deleteUser(userId,userName)
  {
    if(sessionStorage.getItem('userId').toLowerCase() != userName.toLowerCase())
    {
      this.spinner.show();
      this.contractPerfService.deleteUser(userId).subscribe(
        response => {
          
          this.spinner.hide();
            this.toastr.success("User deleted successfully")
            this.getUsers();
        },
        error => {
          this.spinner.hide();
          this.toastr.error("Error Occurred")
        });
    }

    else{
      this.toastr.error("User can not delete itself")
    }
   
  }
}
